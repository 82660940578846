import { isAdmin } from '~/common/isAdmin.ts';
import { createAuthSdk } from '~/packages/auth-sdk/create-auth-sdk.ts';

import { FirebaseProvider } from '~/packages/auth-sdk/providers/FirebaseProvider.ts';
import { createLocalStorageAdaptor } from '~/packages/reactive-storage/storage-adaptors/local-storage.ts';
import { clientHasuraNoAuth } from '~/internals/hasura/client-hasura-no-auth.ts';
import { SignInFirebaseDocument } from '~/api/user.generated.ts';
import { useNuxtApp } from '#imports';

export const authSdk = createAuthSdk({
  defineProviders: ({ hooks }) => {
    return {
      firebase: new FirebaseProvider({
        getApp() {
          const { $firebaseApp } = useNuxtApp();
          return $firebaseApp as any;
        },
        hooks,
      }),
    };
  },
  storageEngine: createLocalStorageAdaptor(),
  hooks: {
    delegate: async (_, tokens) => {
      const resp = await clientHasuraNoAuth.request(SignInFirebaseDocument, {
        req: {
          appId: 'system-admin',
          token: tokens.accessToken,
        },
      });

      if (!resp.login_firebase?.token) {
        throw new Error('Cannot receive token');
      }

      if (!isAdmin(resp.login_firebase.token)) {
        throw new Error('Not an admin');
      }

      return {
        accessToken: resp.login_firebase.token,
        refreshToken: resp.login_firebase.refreshToken,
      };
    },
  },
});
