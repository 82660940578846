import { authSdk } from '~/internals/auth/auth-sdk.ts';
import { useUserStore } from '~/modules/shared/stores/user-store.ts';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  const userStore = useUserStore();

  authSdk.onUpdate(() => {
    const newState = authSdk._getState();

    userStore.isSigningIn = newState.isSigningIn;
    userStore.isSigningOut = newState.isSigningOut;
    userStore.isAuthenticated = Boolean(newState.tokens?.accessToken);
  });

  authSdk.init();
});
