import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HasuraError, isHasuraTokenExpiredError } from '~/internals/errors/hasura-error.ts';
import { NetworkError } from '~/internals/errors/network-error.ts';

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

function appendAuthHeader(config: AxiosRequestConfig, token: string) {
  if (!config.headers) {
    config.headers = {};
  }

  config.headers.Authorization = `Bearer ${token}`;
}

export function createRefreshTokenInterceptor({
  refreshSession,
  getToken,
}: {
  refreshSession: () => Promise<void>;
  getToken: () => Promise<string | null>;
}) {
  return function (axiosInstance: AxiosInstance) {
    let refreshingPromise: Promise<void> | null = null;

    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();

        if (token) {
          appendAuthHeader(config, token);
        }

        // eslint-disable-next-line ts/no-unnecessary-condition
        if (!config.headers) {
          config.headers = {} as any;
        }

        return config;
      },
      (error) => {
        throw error;
      },
    );

    axiosInstance.interceptors.response.use(async (response: AxiosResponse<any, any>) => {
      if (!response.data.errors) {
        return response;
      }
      // eslint-disable-next-line ts/no-unsafe-argument
      const isTokenExpiredError = isHasuraTokenExpiredError(response.data.errors);
      if (!isTokenExpiredError) {
        return response;
      }

      if (response.config._retry) {
        throw new HasuraError({
          message: response.data.errors[0].message,
          extensions: response.data.errors[0].extensions,
        });
      }

      response.config._retry = true;

      if (refreshingPromise) {
        await refreshingPromise;
      } else {
        refreshingPromise = refreshSession();

        await refreshingPromise;
        refreshingPromise = null;

        const newToken = await getToken();
        if (!newToken) {
          throw new NetworkError({
            message: 'Can not refresh session',
          });
        }
        response.config.headers.Authorization = `Bearer ${newToken}`;
      }

      return axiosInstance.request(response.config);
    });
  };
}
