<script setup lang="ts">
  import Toast from 'primevue/toast';
  import CheckIcon from '~/_components/svg-icons/CheckIcon.vue';

  // fixme: add other icons
  const mapIcon = {
    success: CheckIcon,
    info: CheckIcon,
    warn: CheckIcon,
    error: CheckIcon,
  };
</script>

<template>
  <Toast
    group="center"
    position="center"
    :pt="{
      root: {
        class: ['-translate-x-1/2 flex gap-y-3', '-translate-y-1/2'],
      },
      container: { class: 'py-1' },
    }"
  >
    <template #container="{ message }">
      <section
        class="flex w-full gap-2 bg-[#001F3ECC] px-4 py-3 text-white"
        :class="[message?.summary && message?.detail ? '' : 'items-center']"
        style="border-radius: 8px"
      >
        <component :is="mapIcon[message.severity as keyof typeof mapIcon]" width="24" height="24" />
        <div>
          <p class="m-0 text-sm font-semibold">
            {{ message?.summary }}
          </p>
          <p class="m-0 text-sm">
            {{ message?.detail }}
          </p>
        </div>
      </section>
    </template>
  </Toast>
</template>

<style scoped></style>
