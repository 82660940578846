import { safeStringify } from '~/common/json.ts';
import type { HandledError } from '~/internals/errors/base-error.ts';

export interface ErrorOptions {
  message: string;
  cause?: unknown | Error;
}

export class NetworkError extends Error implements HandledError {
  name = 'NetworkError';
  _cause?: Error | unknown;

  constructor({ message, cause }: ErrorOptions) {
    super(message);
    this._cause = cause;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  toString() {
    const errorObj = {
      cause: this._cause,
      name: this.name,
    };

    return safeStringify(errorObj);
  }

  _handle() {
    return 'Lỗi mạng';
  }
}
