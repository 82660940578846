<script setup lang="ts">
  import Dialog from 'primevue/dialog';
  import { provide } from 'vue';
  import { DialogActionSymbol } from '~/_components/StyledDialog/dialog-provider.ts';
  import StyledDialogHeader from '~/_components/StyledDialog/StyledDialogHeader.vue';

  export type DialogSize = 'sm' | 'md' | 'lg' | 'max' | 'custom';

  withDefaults(
    defineProps<{
      title?: string;
      size?: DialogSize;
    }>(),
    {
      size: 'sm',
    },
  );

  const emit = defineEmits<{
    close: [MouseEvent];
  }>();

  const SIZE: Record<DialogSize, string> = {
    sm: 'max-w-[448px]',
    md: 'max-w-[768px]',
    lg: 'max-w-[1200px]',
    max: 'maximize',
    custom: '',
  } as const;

  provide(DialogActionSymbol, {
    close: (e?: MouseEvent) => {
      emit('close', e!);
    },
  });
</script>

<template>
  <Dialog
    modal
    :pt="{
      root: ({ state }: any) => ({
        class: [
          // Shape
          SIZE[size],
          'w-full',
          size === 'max' ? 'px-0' : 'px-4',
          'rounded-3xl',
          'border-0',
          // Size
          'max-h-[90vh]',
          'm-0',
          // Color
          'dark:border',
          'dark:border-surface-700',
          // Transitions
          'transform',
          'scale-100',
          // Maximized State
          {
            'transition-none': size === 'max',
            'transform-none': size === 'max',
            '!w-screen': size === 'max',
            '!h-screen': size === 'max',
            '!max-h-full': size === 'max',
            '!top-0': size === 'max',
            '!left-0': size === 'max',
          },
        ],
      }),
      header: {
        class: ['rounded-t-3xl -mb-0.5'],
      },
      content: ({ state, instance }: any) => ({
        class: [
          // Spacing
          'px-6',
          'pb-8',
          'pt-0',
          'rounded-b-3xl',
          // Shape
          {
            grow: state.maximized,
          },
          // Colors
          'bg-white',
          // Misc
          'overflow-y-auto',
        ],
      }),
      closeButton: {
        class: [
          'absolute top-5 right-9 hidden',
          'flex items-center justify-center',
          'w-8 h-8',
          'border-0',
          'rounded-full',
          'bg-transparent',
          'transition duration-200 ease-in-out',
          'hover:bg-gray-100',
          'focus:outline-none',
          'overflow-hidden',
        ],
      },
    }"
  >
    <template #header>
      <StyledDialogHeader :title="title" />
    </template>
    <slot />
  </Dialog>
</template>

<style scoped></style>
