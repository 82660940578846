import { defineStore } from 'pinia';
import type { ButtonColor, ButtonSize, ButtonVariant } from '~/_components/MQButton.vue';

interface Noti {
  title?: string;
  message?: string;
  debugMessage?: string;
  visible?: boolean;
  type?: 'success' | 'error';
  closeCallback?: () => void;
  labelClose?: string;
  buttons?: {
    label: string;
    loading?: boolean;
    class?: string;
    color?: ButtonColor;
    size?: ButtonSize;
    variant?: ButtonVariant;
    onClick?: () => void;
  }[];
}

export interface DialogState {
  noti: Noti;
}

const initialState: DialogState = {
  noti: {
    title: '',
    message: '',
    debugMessage: '',
    visible: false,
    type: undefined,
  },
};

export const useDialogStore = defineStore('dialog', {
  state(): DialogState {
    return initialState;
  },
  actions: {
    showNoti(params: Noti) {
      this.noti.title = params.title;
      this.noti.labelClose = params.labelClose;
      this.noti.message = params.message;
      this.noti.debugMessage = params.debugMessage;
      this.noti.visible = true;
      this.noti.type = params.type;
      this.noti.buttons = params.buttons;
      this.noti.closeCallback = params.closeCallback;
    },
    hideNoti() {
      this.noti.visible = false;
    },
  },
});
