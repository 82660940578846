<script setup lang="ts">
  import { inject } from 'vue';
  import { DialogActionSymbol } from '~/_components/StyledDialog/dialog-provider.ts';
  import CloseIcon from '~/_components/svg-icons/CloseIcon.vue';

  withDefaults(
    defineProps<{
      title?: string;
      showClose?: boolean;
    }>(),
    {
      showClose: true,
    },
  );

  const actions = inject(DialogActionSymbol);
  if (!actions) {
    console.warn('StyledDialogHeader must be used inside a StyledDialog');
  }
</script>

<template>
  <div class="relative rounded-t-3xl bg-white px-4 pb-3 pt-4 text-center sm:px-6 sm:pt-6">
    <h3 v-if="title" class="text-center font-bold sm:text-2xl">
      {{ title }}
    </h3>
    <slot v-else />
    <div class="absolute left-0 top-0">
      <slot name="headerLeftIcon" />
    </div>
    <button
      v-if="showClose"
      aria-label="Close"
      class="group/close absolute right-4 top-5 flex items-center justify-center rounded-full border-0 bg-transparent p-1 transition duration-200 ease-in-out hover:bg-gray-100"
      @click="actions?.close($event)"
    >
      <CloseIcon class="size-5" />
    </button>
  </div>
</template>

<style scoped></style>
