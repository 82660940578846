import Tailwind from 'primevue/passthrough/tailwind';
import type { PrimeVuePTOptions } from 'primevue/config';

console.log(Tailwind.calendar);
export default {
  ...Tailwind,
  calendar: {
    ...Tailwind.calendar,
    // @ts-expect-error - it should be dayLabel, but it does not work, but daylabel does
    daylabel: function daylabel(_ref70) {
      const context = _ref70.context;
      return {
        class: [
          'w-10 h-10 rounded-full transition-shadow duration-200 border',
          'flex items-center justify-center mx-auto overflow-hidden relative',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
          {
            'border-transparent bg-gray-200 opacity-60 cursor-default': context.disabled,
            'cursor-pointer': !context.disabled,
          },
          {
            'text-gray-600 dark:text-white/70 bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80':
              !context.selected && !context.disabled,
            'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
          },
        ],
      };
    },
    input: {
      class: ['w-full ', 'h-full', 'p-0', 'm-0', 'rounded-xl', 'outline-none', 'appearance-none', 'cursor-pointer'],
    },
    // root: {
    //   input: {
    //     class: ['w-full'],
    //   },
    // },
  },
  card: {
    ...Tailwind.card,
    root: {
      class: [
        'rounded-xl sm:rounded-3xl bg-white',
        // 'dark:bg-gray-900 dark:text-white'
      ],
    },
    body: 'p-0',
    content: 'py-0',
  },
  // @ts-expect-error - TS doesn't like the `button` property.
  button:
    'flex items-center justify-center gap-1 whitespace-nowrap transition-colors rounded-xl disabled:pointer-events-none border rounded-xl border-gray-1 text-black hover:bg-secondary px-4 h-[40px]',
  checkbox: {
    root: {
      class: ['relative', 'inline-flex', 'align-bottom', 'w-6', 'h-6', 'cursor-pointer', 'select-none'],
    },
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',
        // Size
        'w-6',
        'h-6',
        // Shape
        'rounded-md',
        'border-2',
        // Colors
        {
          'border-surface-200 bg-surface-0 dark:border-surface-700 dark:bg-surface-900': !context.checked && !props.invalid,
          'border-primary bg-primary dark:border-primary-400 dark:bg-primary-400': context.checked,
        },
        // Invalid State
        { 'border-red-500 dark:border-red-400': props.invalid },
        // States
        {
          'peer-hover:border-primary-500 dark:peer-hover:border-primary-400': !props.disabled && !context.checked && !props.invalid,
          'peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300 peer-hover:border-primary-700 dark:peer-hover:border-primary-300':
            !props.disabled && context.checked,
          'peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20':
            !props.disabled,
          'cursor-default opacity-60': props.disabled,
        },
        // Transitions
        'transition-colors',
        'duration-200',
      ],
    }),
    input: {
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'z-10',
        'p-0',
        'm-0',
        'opacity-0',
        'rounded-md',
        'outline-none',
        'border-2 border-surface-200 dark:border-surface-700',
        'appearance-none',
        'cursor-pointer',
      ],
    },
    icon: {
      class: ['text-base leading-none', 'w-4', 'h-4', 'text-white dark:text-surface-900', 'transition-all', 'duration-200'],
    },
  },
  menu: {
    ...Tailwind.menu,
    root: 'bg-white dark:bg-gray-900 menu-shadow dark:text-white/80 border border-menu-border dark:border-blue-900/40 rounded-lg',
    menu: {
      class: ['overflow-hidden rounded-[6px]'],
    },
    content: ({ context }) => ({
      class: [
        'text-gray-700 dark:text-white/80 transition-shadow duration-200 rounded-none px-3',
        'hover:text-gray-700 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80', // Hover
        {
          '': context.focused,
        },
      ],
    }),
  },
  dropdown: {
    ...Tailwind.dropdown,
    root: ({ props }) => ({
      class: [
        'cursor-pointer relative select-none px-4 flex items-center',
        'bg-white border transition-colors duration-200 ease-in-out rounded-xl',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300',
        'w-full',
        'hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ],
    }),
    input: ({ props }) => ({
      class: [
        'cursor-pointer  text-menu-input-text text-sm block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
        'bg-transparent border-0',
        'dark:text-white/80',
        'transition duration-200 bg-transparent rounded appearance-none text-base',
        'focus:outline-none focus:shadow-none',
        { 'pr-7': props.showClear },
      ],
    }),
    list: 'list-none m-0 py-1 menu-content',
    item: () => ({
      class: [
        'relative text-sm text-black w-full',
        // 'm-0 px-2 py-2 mx-1 border-b border-menu-border transition-shadow duration-200 rounded-none',
        // 'dark:text-white/80 dark:hover:bg-gray-800',
        // 'hover:text-gray-700 hover:bg-gray-200',
        {
          // 'bg-red': index === 0,
          // 'text-gray-700': !context.focused && !context.selected,
          // 'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
          // 'bg-blue-400 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
          // 'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
        },
      ],
    }),

    itemGroup: {
      class: ['m-0 px-2 mx-1 ', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
    },
    header: {
      class: [
        'border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg',
        'dark:bg-gray-800 dark:text-white/80 dark:border-blue-900/40',
      ],
    },
    trigger: {
      class: ['bg-transparent text-gray-500 w-6 rounded-tr-lg rounded-br-lg'],
    },
  },
  autocomplete: {
    ...Tailwind.autocomplete,
    root: ({ props }) => ({
      class: [
        'cursor-pointer relative select-none px-4 flex items-center',
        'bg-white border transition-colors duration-200 ease-in-out rounded-xl',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300',
        'w-full',
        'hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ],
    }),
    input: () => ({
      class: [
        'cursor-pointer  text-menu-input-text text-sm block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
        'bg-transparent border-0',
        'dark:text-white/80',
        'transition duration-200 bg-transparent rounded appearance-none text-base',
        'focus:outline-none focus:shadow-none',
      ],
    }),
    list: 'list-none m-0 py-1 menu-content',
    item: () => ({
      class: [
        'relative text-sm text-black w-full',
        // 'm-0 px-2 py-2 mx-1 border-b border-menu-border transition-shadow duration-200 rounded-none',
        // 'dark:text-white/80 dark:hover:bg-gray-800',
        // 'hover:text-gray-700 hover:bg-gray-200',
        {
          // 'bg-red': index === 0,
          // 'text-gray-700': !context.focused && !context.selected,
          // 'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
          // 'bg-blue-400 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
          // 'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
        },
      ],
    }),
    itemGroup: {
      class: ['m-0 px-2 mx-1 ', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
    },
    loadingIcon: {
      class: ['text-primary'],
    },
    emptyMessage: {
      class: ['text-gray-500 px-3 py-2'],
    },
    // @ts-expect-error - it should be dropdownButton, but it does not work, but dropdownbutton does
    dropdownbutton: {
      root: {
        class: ['h-full flex items-center justify-center'],
      },
      label: {
        class: ['hidden'],
      },
    },
  },
  paginator: {
    ...Tailwind.paginator,
    root: {
      class: [
        'flex items-center justify-center flex-wrap',
        'bg-white',
        'dark:bg-gray-900 dark:text-white/60 dark:border-blue-900/40', // Dark Mode
      ],
    },
    previousPageButton: ({ context }) => ({
      class: [
        'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
        'border-0 text-gray-500 min-w-[1.5rem] h-6 mr-3 rounded-md',
        'transition duration-200',
        'dark:text-white',
        {
          'cursor-default pointer-events-none opacity-20': context.disabled,
        },
      ],
    }),
    nextPageButton: ({ context }) => ({
      class: [
        'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
        'border-0 text-gray-500 min-w-[1.5rem] h-6 ml-3 rounded-md',
        'transition duration-200',
        'dark:text-white', // Dark Mode
        {
          'cursor-default pointer-events-none opacity-20': context.disabled,
        },
      ],
    }),
    // @ts-expect-error - it should be pageButton, but it does not work, but pagebutton does
    pagebutton: ({ context }) => ({
      class: [
        'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
        'min-w-[1.5rem] h-6 rounded-full mx-1',
        'text-sm font-bold text-black border border-pagination-border-page',
        'transition duration-200',
        'dark:border-blue-300 dark:text-white', // Dark Mode
        {
          'border-pagination-border-page-active text-primary dark:bg-blue-300': context.active,
        },
      ],
    }),
  },
  breadcrumb: {
    ...Tailwind.breadcrumb,
    root: {
      class: [''],
    },
    separator: {
      class: ['mx-1 dark:text-white/70', 'flex items-center'],
    },
  },
  skeleton: {
    root: ({ props }) => ({
      class: [
        'overflow-hidden',
        'bg-gray-300 dark:bg-gray-800',
        'after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:content after:w-full after:h-full after:bg-blue-400 after:left-full after:transform after:translate-x-full after:z-10 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent animate-pulse',
        {
          'rounded-md': props.shape !== 'circle',
          'rounded-full': props.shape === 'circle',
        },
      ],
    }),
  },
  tabview: {
    ...Tailwind.tabview,
    nav: {
      class: [
        'flex flex-1 list-none m-0 p-0',
        'bg-white border border-gray-300 border-0',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 ',
      ], // Flex, list, margin, padding, and border styles.
    },
    tabpanel: {
      // @ts-expect-error - TS doesn't like the `tabpanel` property.
      ...Tailwind.tabview?.tabpanel,
      headerAction: ({ parent, context }: any) => ({
        class: [
          'text-sm cursor-pointer items-center cursor-pointer flex overflow-hidden relative select-none text-decoration-none user-select-none', // Flex and overflow styles.
          'p-[14px] !mb-0 font-bold rounded-t-lg transition-shadow duration-200 m-0', // Border, padding, font, and transition styles.
          'transition-colors duration-200', // Transition duration style.
          {
            'bg-white text-secondary-text hover:bg-white hover:border-gray-400 hover:text-gray-600 dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 dark:hover:bg-gray-800/80':
              parent.state.d_activeIndex !== context.index, // Condition-based hover styles.
            'bg-white border-b-2 border-primary text-primary dark:bg-gray-900 dark:border-blue-300 dark:text-blue-300':
              parent.state.d_activeIndex === context.index, // Condition-based active styles.
          },
        ],
        style: 'margin-bottom:-2px', // Negative margin style.
      }),
    },
  },
  textarea: {
    root: ({ context }) => ({
      class: [
        'm-0',
        'placeholder-text-placeholder text-black dark:text-white/80 bg-white dark:bg-gray-900 p-3 border dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-xl',
        'hover:border-primary focus:outline-none focus:border-primary ',
        {
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
        },
      ],
    }),
  },
  inputtext: {
    root: ({ props, context }) => ({
      class: [
        'm-0',
        'text-black dark:text-white/80 bg-white dark:bg-gray-900 transition-colors duration-200 appearance-none rounded-xl',
        {
          'focus:outline-none focus:outline-offset-0': !context.disabled,
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
        },
        {
          'text-lg px-4 py-4': props.size === 'large',
          'text-xs px-2 py-2': props.size === 'small',
          'p-3 text-base': !props.size,
        },
      ],
    }),
  },
  toast: {
    ...Tailwind.toast,
    root: ({ props }) => ({
      class: [
        //Size and Shape
        'sm:w-96 rounded-md',
        // Positioning
        {
          '-translate-x-2/4': props.position === 'top-center' || props.position === 'bottom-center',
        },
      ],
    }),
  },
} satisfies PrimeVuePTOptions;
