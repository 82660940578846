<script lang="ts" setup>
  import dayjs from 'dayjs';
  import Toast from 'primevue/toast';
  import relativeTime from 'dayjs/plugin/relativeTime';
  import { NuxtLayout, NuxtPage } from '#components';

  import MQToastCenter from '~/_components/MQToastCenter.vue';
  import MQConfirmDialog from '~/_components/MQConfirmDialog.vue';
  import MQConfirmDeleteDialog from '~/_components/MQConfirmDeleteDialog.vue';
  import MQNotificationDialog from '~/_components/MQNotificationDialog.vue';
  import StyledDialogDynamic from '~/_components/StyledDialog/StyledDialogDynamic.vue';
  import 'dayjs/locale/vi';
  import 'primeicons/primeicons.css';
  import './styles/main.css';
  import useFetchPreData from '~/_composables/useFetchPreData.ts';

  dayjs.extend(relativeTime);

  dayjs.locale('vi');

  useFetchPreData();
</script>

<template>
  <NuxtLayout class="relative">
    <NuxtPage />
    <Toast />
    <MQToastCenter group="center" position="center" />
    <MQConfirmDialog />
    <MQConfirmDeleteDialog />
    <MQNotificationDialog />
    <StyledDialogDynamic />
  </NuxtLayout>
</template>

<style>
  :root {
    font-family: Quicksand, sans-serif;
  }

  .menu-shadow {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  }

  .menu-content > li:last-child {
    border: none;
  }

  /*hack for hiding input background when autocomplete*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  :root {
    --primary-50: 236 253 245;
    --primary-100: 209 250 229;
    --primary-200: 167 243 208;
    --primary-300: 110 231 183;
    --primary-400: 52 211 153;
    --primary-500: 16 185 129;
    --primary-600: 5 150 105;
    --primary-700: 4 120 87;
    --primary-800: 6 95 70;
    --primary-900: 4 78 56;
    --primary-950: 2 44 34;
    --surface-0: 255 255 255;
    --surface-50: 248 250 252;
    --surface-100: 241 245 249;
    --surface-200: 226 232 240;
    --surface-300: 203 213 225;
    --surface-400: 148 163 184;
    --surface-500: 100 116 139;
    --surface-600: 71 85 105;
    --surface-700: 45 55 72;
    --surface-800: 30 41 59;
    --surface-900: 15 23 42;
    --surface-950: 3 6 23;
  }
</style>
