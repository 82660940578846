function stringifyReplacer(_key: string | number | symbol, value: any) {
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  const specialTypes = ['function', 'bigint', 'symbol'];
  // We use different operation for special kinds
  if (specialTypes.includes(typeof value)) {
    return String(value);
  }

  return value;
}

export function safeStringify(target: any, spacing?: number): string {
  return JSON.stringify(target, stringifyReplacer, spacing);
}
