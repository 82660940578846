import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import type { HttpClientOptions, Interceptor } from './network-types.ts';

export function createHttpClient(configs: AxiosRequestConfig, options?: HttpClientOptions) {
  const ins = axios.create(configs);
  const interceptors: Interceptor[] = options?.interceptors ?? [];

  interceptors.forEach((interceptor) => {
    interceptor(ins);
  });

  return {
    async request<TResponse>(params: AxiosRequestConfig): Promise<TResponse> {
      const { data, headers, signal, method, url, ...rest } = params;
      const mergedHeaders = { ...options?.defaultHeaders, ...headers };

      const resp = await ins<TResponse>(url as string, {
        timeout: options?.timeout,
        method: method ?? options?.method,
        data,
        headers: mergedHeaders,
        signal,
        ...rest,
      });

      return resp.data;
    },
  };
}
