<script setup lang="ts"></script>

<template>
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector (Stroke)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8495 0.481351C13.047 0.6744 13.0506 0.990963 12.8575 1.18841L4.72041 9.51106C4.62702 9.60657 4.49929 9.66075 4.36571 9.6615C4.23213 9.66226 4.1038 9.60952 4.00934 9.51506L0.146447 5.65217C-0.0488156 5.45691 -0.0488155 5.14033 0.146447 4.94506C0.341709 4.7498 0.658291 4.7498 0.853554 4.94506L4.35888 8.4504L12.1425 0.489321C12.3355 0.291871 12.6521 0.288303 12.8495 0.481351Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped></style>
