import type { DialogProps } from 'primevue/dialog';
import type { InjectionKey } from 'vue';
import type { DialogSize } from '~/_components/StyledDialog/StyledDialog.vue';

export const DialogActionSymbol = Symbol('DialogAction') as InjectionKey<{
  close: (e?: MouseEvent) => void;
}>;

export interface StyledDialogProps extends DialogProps {
  title?: string;
  size?: DialogSize;
  class?: string;
}
