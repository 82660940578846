import { default as _91_46_46_46unknown_93l6IIo3LWQ4Meta } from "/opt/buildhome/repo/src/pages/[...unknown].vue?macro=true";
import { default as bannerse0I1uyRHgZMeta } from "/opt/buildhome/repo/src/pages/banners.vue?macro=true";
import { default as mqhouse_45config3ZfGPVd44yMeta } from "/opt/buildhome/repo/src/pages/configs/mqhouse-config.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as paymentZD40a7vxG8Meta } from "/opt/buildhome/repo/src/pages/payment.vue?macro=true";
import { default as posts_45hotx8A9a4cqfhMeta } from "/opt/buildhome/repo/src/pages/posts-hot.vue?macro=true";
import { default as posts_45ownerea4YrIbmQkMeta } from "/opt/buildhome/repo/src/pages/posts-owner.vue?macro=true";
import { default as posts_45verifyNDfUdpfIZsMeta } from "/opt/buildhome/repo/src/pages/posts-verify.vue?macro=true";
import { default as _91id_93YDDR9clkdBMeta } from "/opt/buildhome/repo/src/pages/posts/[id].vue?macro=true";
import { default as createey6SyXhgdwMeta } from "/opt/buildhome/repo/src/pages/posts/create.vue?macro=true";
import { default as indexrFXn3a2R0uMeta } from "/opt/buildhome/repo/src/pages/posts/index.vue?macro=true";
import { default as sign_45inoKDrpiDbYyMeta } from "/opt/buildhome/repo/src/pages/sign-in.vue?macro=true";
import { default as userssHFQarndhWMeta } from "/opt/buildhome/repo/src/pages/users.vue?macro=true";
export default [
  {
    name: _91_46_46_46unknown_93l6IIo3LWQ4Meta?.name ?? "unknown",
    path: _91_46_46_46unknown_93l6IIo3LWQ4Meta?.path ?? "/:unknown(.*)*",
    meta: _91_46_46_46unknown_93l6IIo3LWQ4Meta || {},
    alias: _91_46_46_46unknown_93l6IIo3LWQ4Meta?.alias || [],
    redirect: _91_46_46_46unknown_93l6IIo3LWQ4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/[...unknown].vue").then(m => m.default || m)
  },
  {
    name: bannerse0I1uyRHgZMeta?.name ?? "banners",
    path: bannerse0I1uyRHgZMeta?.path ?? "/banners",
    meta: bannerse0I1uyRHgZMeta || {},
    alias: bannerse0I1uyRHgZMeta?.alias || [],
    redirect: bannerse0I1uyRHgZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/banners.vue").then(m => m.default || m)
  },
  {
    name: mqhouse_45config3ZfGPVd44yMeta?.name ?? "configs-mqhouse-config",
    path: mqhouse_45config3ZfGPVd44yMeta?.path ?? "/configs/mqhouse-config",
    meta: mqhouse_45config3ZfGPVd44yMeta || {},
    alias: mqhouse_45config3ZfGPVd44yMeta?.alias || [],
    redirect: mqhouse_45config3ZfGPVd44yMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/configs/mqhouse-config.vue").then(m => m.default || m)
  },
  {
    name: indexhGpynX1cdDMeta?.name ?? "index",
    path: indexhGpynX1cdDMeta?.path ?? "/",
    meta: indexhGpynX1cdDMeta || {},
    alias: indexhGpynX1cdDMeta?.alias || [],
    redirect: indexhGpynX1cdDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: paymentZD40a7vxG8Meta?.name ?? "payment",
    path: paymentZD40a7vxG8Meta?.path ?? "/payment",
    meta: paymentZD40a7vxG8Meta || {},
    alias: paymentZD40a7vxG8Meta?.alias || [],
    redirect: paymentZD40a7vxG8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: posts_45hotx8A9a4cqfhMeta?.name ?? "posts-hot",
    path: posts_45hotx8A9a4cqfhMeta?.path ?? "/posts-hot",
    meta: posts_45hotx8A9a4cqfhMeta || {},
    alias: posts_45hotx8A9a4cqfhMeta?.alias || [],
    redirect: posts_45hotx8A9a4cqfhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts-hot.vue").then(m => m.default || m)
  },
  {
    name: posts_45ownerea4YrIbmQkMeta?.name ?? "posts-owner",
    path: posts_45ownerea4YrIbmQkMeta?.path ?? "/posts-owner",
    meta: posts_45ownerea4YrIbmQkMeta || {},
    alias: posts_45ownerea4YrIbmQkMeta?.alias || [],
    redirect: posts_45ownerea4YrIbmQkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts-owner.vue").then(m => m.default || m)
  },
  {
    name: posts_45verifyNDfUdpfIZsMeta?.name ?? "posts-verify",
    path: posts_45verifyNDfUdpfIZsMeta?.path ?? "/posts-verify",
    meta: posts_45verifyNDfUdpfIZsMeta || {},
    alias: posts_45verifyNDfUdpfIZsMeta?.alias || [],
    redirect: posts_45verifyNDfUdpfIZsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts-verify.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YDDR9clkdBMeta?.name ?? "posts-id",
    path: _91id_93YDDR9clkdBMeta?.path ?? "/posts/:id()",
    meta: _91id_93YDDR9clkdBMeta || {},
    alias: _91id_93YDDR9clkdBMeta?.alias || [],
    redirect: _91id_93YDDR9clkdBMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: createey6SyXhgdwMeta?.name ?? "posts-create",
    path: createey6SyXhgdwMeta?.path ?? "/posts/create",
    meta: createey6SyXhgdwMeta || {},
    alias: createey6SyXhgdwMeta?.alias || [],
    redirect: createey6SyXhgdwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts/create.vue").then(m => m.default || m)
  },
  {
    name: indexrFXn3a2R0uMeta?.name ?? "posts",
    path: indexrFXn3a2R0uMeta?.path ?? "/posts",
    meta: indexrFXn3a2R0uMeta || {},
    alias: indexrFXn3a2R0uMeta?.alias || [],
    redirect: indexrFXn3a2R0uMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45inoKDrpiDbYyMeta?.name ?? "sign-in",
    path: sign_45inoKDrpiDbYyMeta?.path ?? "/sign-in",
    meta: sign_45inoKDrpiDbYyMeta || {},
    alias: sign_45inoKDrpiDbYyMeta?.alias || [],
    redirect: sign_45inoKDrpiDbYyMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: userssHFQarndhWMeta?.name ?? "users",
    path: userssHFQarndhWMeta?.path ?? "/users",
    meta: userssHFQarndhWMeta || {},
    alias: userssHFQarndhWMeta?.alias || [],
    redirect: userssHFQarndhWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/users.vue").then(m => m.default || m)
  }
]