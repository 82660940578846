import { initializeApp } from 'firebase/app';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  let firebaseConfig;

  if (import.meta.env.VITE_ENV === 'production') {
    firebaseConfig = {
      apiKey: 'AIzaSyCoIdvBon11pommSF0tH-9jhlvmIeybBLo',
      authDomain: 'mq-house-prod.firebaseapp.com',
      projectId: 'mq-house-prod',
      storageBucket: 'mq-house-prod.appspot.com',
      messagingSenderId: '464074778434',
      appId: '1:464074778434:web:cf296ba3bc7032844c8adb',
      measurementId: 'G-RG6YNS1NGJ',
    };
  } else {
    firebaseConfig = {
      apiKey: 'AIzaSyAVTVyyo8qzFpR6yz40aJYeDWfD7XCeXEA',
      authDomain: 'mqhouse-4bf76.firebaseapp.com',
      projectId: 'mqhouse-4bf76',
      storageBucket: 'mqhouse-4bf76.appspot.com',
      messagingSenderId: '969020250444',
      appId: '1:969020250444:web:01f8d10fef6b5ba1dd17e9',
      measurementId: 'G-VNLC5H3KZM',
    };
  }

  const app = initializeApp(firebaseConfig);

  return {
    provide: {
      firebaseApp: app,
    },
  };
});
