<script setup lang="ts"></script>

<template>
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45 31C45 37.0751 40.0751 42 34 42C27.9249 42 23 37.0751 23 31C23 24.9249 27.9249 20 34 20C40.0751 20 45 24.9249 45 31ZM160 100C160 133.137 133.137 160 100 160C66.8629 160 40 133.137 40 100C40 66.8629 66.8629 40 100 40C133.137 40 160 66.8629 160 100ZM176 68C178.761 68 181 65.7614 181 63C181 60.2386 178.761 58 176 58C173.239 58 171 60.2386 171 63C171 65.7614 173.239 68 176 68ZM18 150C18 152.209 16.2091 154 14 154C11.7909 154 10 152.209 10 150C10 147.791 11.7909 146 14 146C16.2091 146 18 147.791 18 150ZM108 36C109.657 36 111 34.6569 111 33C111 31.3431 109.657 30 108 30C106.343 30 105 31.3431 105 33C105 34.6569 106.343 36 108 36ZM166 135C166 136.657 164.657 138 163 138C161.343 138 160 136.657 160 135C160 133.343 161.343 132 163 132C164.657 132 166 133.343 166 135ZM130 180C131.657 180 133 178.657 133 177C133 175.343 131.657 174 130 174C128.343 174 127 175.343 127 177C127 178.657 128.343 180 130 180Z"
      fill="#E85047"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M115.14 81C114.117 81 113.135 81.4066 112.411 82.1303L100 94.5412L87.5891 82.1303C86.8652 81.4066 85.8835 81 84.8598 81C83.8362 81 82.8542 81.4068 82.1303 82.1305C81.4066 82.8544 81 83.8362 81 84.8598C81 85.8835 81.4067 86.8653 82.1304 87.5892L94.5412 100L82.1303 112.411C81.4066 113.135 81 114.117 81 115.14C81 116.164 81.4068 117.146 82.1305 117.87C82.8544 118.593 83.8362 119 84.8598 119C85.8834 119 86.8653 118.593 87.5892 117.87L100 105.459L112.411 117.87C113.135 118.593 114.117 119 115.14 119C116.164 119 117.146 118.593 117.87 117.87C118.593 117.146 119 116.164 119 115.14C119 114.117 118.593 113.135 117.87 112.411L105.459 100L117.87 87.5891C118.593 86.8652 119 85.8834 119 84.8598C119 83.8362 118.593 82.8542 117.87 82.1303C117.146 81.4066 116.164 81 115.14 81ZM116.047 82.1476C116.464 82.2868 116.846 82.5213 117.163 82.8375C117.498 83.1727 117.741 83.5826 117.876 84.028C117.741 83.5826 117.498 83.1727 117.163 82.8374C116.846 82.5212 116.464 82.2868 116.047 82.1476Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
