import type { Adaptor, UnregisterFn } from '../storage-types';

export interface CustomEventDetail<T = unknown> {
  key: string;
  value: T;
}

type Callback = (val: any) => void;

export function createLocalStorageAdaptor(): Adaptor {
  const customEventKey = 'REACTIVE_STORAGE_localStorage';

  function fireCustomEvent(key: string, newVal: unknown) {
    const event = new CustomEvent(customEventKey, {
      detail: { key, value: newVal },
    });

    window.dispatchEvent(event);
  }

  function listenCustomEvent(callback: Callback): UnregisterFn {
    const handler = (e: CustomEvent) => {
      callback(e.detail);
    };
    // do we need to fire cross browser tab event?
    window.addEventListener(customEventKey, handler as EventListener);

    return () => {
      window.removeEventListener(customEventKey, handler as EventListener);
    };
  }

  return {
    get: (key) => {
      return window.localStorage.getItem(key);
    },
    set: (key, newValue) => {
      window.localStorage.setItem(key, newValue);
      fireCustomEvent(key, newValue);
    },
    remove: (key) => {
      window.localStorage.removeItem(key);
    },
    clear: () => {
      window.localStorage.clear();
    },
    onValueChanged<Value = unknown>(key: string, listener: (val: Value) => void, shouldSerialize: boolean): UnregisterFn {
      return listenCustomEvent((detail: CustomEventDetail<string>) => {
        if (key === detail.key) {
          listener(shouldSerialize ? (JSON.parse(detail.value) as Value) : (detail.value as Value));
        }
      });
    },
  };
}

export type LocalStorageValue<T> = T | null;
