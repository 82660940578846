import type { AuthHook, BaseProvider, StorageEngine, Tokens } from '~/packages/auth-sdk/auth-sdk-types.ts';

export interface AuthSdkOptions<P> {
  defineProviders: ({ hooks }: { hooks: AuthHook }) => P;
  // for universal login, we need to use cookie as StorageEngine
  storageEngine: StorageEngine;
  hooks?: Partial<AuthHook>;
}

export interface InternalState {
  isSignedIn: boolean;
  tokens: null | Tokens;
  isSigningIn: boolean;
  isSigningOut: boolean;
}

export function createAuthSdk<P extends Record<string, any>, Keys extends keyof P>({
  defineProviders,
  storageEngine,
  hooks,
}: AuthSdkOptions<P>) {
  const BASE_KEY = 'AUTH_SDK';
  const PROVIDER_KEY = 'PROVIDER_KEY';
  const TOKEN_KEY = 'TOKEN_KEY';
  const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';

  const _internalState: InternalState = {
    isSignedIn: false,
    tokens: null,
    isSigningIn: false,
    isSigningOut: false,
  };
  const listeners = new Set<() => void>();

  const _proxyInternalState = new Proxy(_internalState, {
    get(_, p, receiver) {
      return Reflect.get(_internalState, p, receiver);
    },
    set(_, p, newValue) {
      const success = Reflect.set(_internalState, p, newValue);

      listeners.forEach((fn) => fn());

      return success;
    },
  });

  const _internalHooks: AuthHook = {
    beforeSignIn: async () => {
      _proxyInternalState.isSigningIn = true;
    },
    afterSignIn: async () => {
      _proxyInternalState.isSigningIn = false;
    },
    beforeSignOut: async () => {
      _proxyInternalState.isSigningOut = true;
    },
    afterSignOut: async () => {
      _proxyInternalState.isSigningOut = false;
    },
    delegate: async (providerKey, tokens) => {
      storageEngine.set(getStoreKey(PROVIDER_KEY), providerKey);
      if (hooks?.delegate) {
        _proxyInternalState.tokens = await hooks.delegate(providerKey, tokens);
      } else {
        _proxyInternalState.tokens = tokens;
      }

      storeTokens();

      return _proxyInternalState.tokens;
    },
  };

  function storeTokens() {
    storageEngine.set(getStoreKey(TOKEN_KEY), _proxyInternalState.tokens?.accessToken ?? '');
    storageEngine.set(getStoreKey(REFRESH_TOKEN_KEY), _proxyInternalState.tokens?.refreshToken ?? '');
  }

  let providers: P = null!; // will set in init

  function getStoreKey(key: string) {
    return `${BASE_KEY}_${key}`;
  }

  function getRawProvider<Key extends Keys>(key: Key): P[Key] | null {
    return providers[key] ?? null;
  }

  function whichProvider(): BaseProvider | null {
    const providerKey = storageEngine.get(getStoreKey(PROVIDER_KEY));

    return getRawProvider(providerKey as Keys);
  }

  function syncState() {
    const token = storageEngine.get(getStoreKey(TOKEN_KEY));
    const refreshToken = storageEngine.get(getStoreKey(REFRESH_TOKEN_KEY));
    if (token) {
      _proxyInternalState.tokens = {
        accessToken: token,
        refreshToken: refreshToken ?? undefined,
      };
    }
  }

  return {
    init() {
      providers = defineProviders({ hooks: _internalHooks });

      Object.keys(providers).forEach((key) => {
        providers[key].setKey(key);
      });

      syncState();
    },
    with<Key extends Keys>(providerKey: Key) {
      const provider = getRawProvider(providerKey);
      if (!provider) {
        throw new Error(`No provider found for key: ${String(providerKey)}`);
      }

      return provider;
    },

    hasStoredSession() {
      return storageEngine.get(getStoreKey(TOKEN_KEY)) !== null && storageEngine.get(getStoreKey(REFRESH_TOKEN_KEY)) !== null;
    },

    isSignedIn(force?: boolean) {
      const provider = whichProvider();
      if (!provider) {
        return false;
      }

      syncState();

      return provider.isSignedIn(force);
    },

    refreshSession() {
      const provider = whichProvider();
      if (!provider) {
        throw new Error('Not signed in yet');
      }

      syncState();

      return provider.refreshSession();
    },

    async getAccessToken(): Promise<string | null> {
      const provider = whichProvider();
      if (!provider) {
        return null;
      }

      syncState();

      return _proxyInternalState.tokens?.accessToken ?? null;
    },

    async signOut() {
      const provider = whichProvider();
      if (!provider) {
        throw new Error('Not signed in yet');
      }

      await provider.signOut();
      syncState();
      [PROVIDER_KEY, TOKEN_KEY, REFRESH_TOKEN_KEY].forEach((key) => {
        storageEngine.remove(getStoreKey(key));
      });
    },

    getUser<TUser>(): Promise<TUser | null> {
      const provider = whichProvider();
      if (!provider) {
        throw new Error('Not signed in yet');
      }

      return provider.getUser() as any;
    },
    onUpdate(fn: () => void) {
      listeners.add(fn);

      return () => {
        listeners.delete(fn);
      };
    },
    _getState() {
      return _internalState;
    },
  } as const;
}
