import * as qs from 'qs';
import dayjs from 'dayjs';
import getYouTubeId from 'get-youtube-id';
import { HOUSE_TYPES, LIST_CATEGORIES_HOUSE, LIST_DISTRICT_DEFAULT, OPTIONS_CATEGORY_STATUS, OPTIONS_PAYMENT_STATUS } from './constants.ts';
import type { GetPackagesQuery } from '~/api/user.generated.ts';
import useLogger from '~/_composables/useLogger.ts';

export function shortenString(str: string, maxLength: number, separator: string = '...') {
  if (str.length > maxLength + separator.length) {
    return str.substring(0, maxLength - separator.length) + separator;
  }
  return str;
}

export function mapDistrict(districtCode: string | undefined) {
  if (!districtCode) {
    return '';
  }
  return LIST_DISTRICT_DEFAULT.find((item) => item.id === districtCode)?.name;
}

export function mapHouseType(houseType: string | undefined) {
  if (!houseType) {
    return '';
  }
  return HOUSE_TYPES.find((type) => type.id === houseType)?.name;
}

export function isYoutubeUrl(url: string | null | undefined) {
  if (!url) {
    return false;
  }

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/g;
  return youtubeRegex.test(url);
}

export function formatLocationGraphql(location: `(${number},${number})` | null | undefined): [number, number] {
  if (!location) {
    return [0, 0];
  }
  return location
    .replace('(', '')
    .replace(')', '')
    .split(',')
    .map((item: string) => Number(item)) as [number, number];
}

export function parseQuerySearch<T>(query: string): T {
  return qs.parse(query, { ignoreQueryPrefix: true }) as T;
}

export function formatUserName(name: string | undefined) {
  return shortenString(name ?? '', 20);
}

export function isHighLengthWord(roomTitle: string | undefined) {
  if (typeof roomTitle !== 'string') {
    return false;
  }

  const strings = roomTitle.split(' ');

  return strings.some((str) => str.length > 20);
}

export function readableTimeFromNow(time: string | undefined) {
  return dayjs(time).fromNow();
}

export function isValidUrl(url: string | undefined | null): url is string {
  if (!url) {
    return false;
  }
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

export function extractYoutubeId(url: string | undefined | null) {
  if (!url) {
    return null;
  }
  return getYouTubeId(url);
}

function convertYoutubeIdToEmbedUrl(id: string) {
  return `https://www.youtube.com/embed/${id}`;
}

export function convertYoutubeUrlToEmbedUrl(url: string) {
  const id = extractYoutubeId(url);
  if (!id) {
    return null;
  }
  return convertYoutubeIdToEmbedUrl(id);
}

export function filterTruthy<TValue>(value: TValue | null | undefined): value is TValue {
  return Boolean(value);
}

export function getEscapedGraphql(str: string) {
  return str.replace(/[\\%]/g, '\\$&');
}

export function toGraphqlSearch(str: string) {
  return `%${getEscapedGraphql(str)}%`;
}

export function toArr<T>(s: T | T[]): T[] {
  return Array.isArray(s) ? s : [s];
}

export function mapCategory(houseCategory: string | undefined) {
  if (!houseCategory) {
    return '';
  }
  return LIST_CATEGORIES_HOUSE.find((type) => type.id === houseCategory)?.name;
}

export const sleep = (milliseconds: number) => new Promise<void>((resolve: () => void) => setTimeout(resolve, milliseconds));

export const parseJson = (json: string, type: 'String' | 'Array' | 'Object' | 'Boolean') => {
  const logger = useLogger();
  try {
    return JSON.parse(json);
  } catch (e) {
    console.log(e);
    logger.error('Cannot parse json', {
      json,
      error: e,
    });
    return type === 'Array' ? [] : type === 'Object' ? {} : type === 'Boolean' ? false : '';
  }
};

export function mapCategoryStatus(status: string | undefined) {
  if (!status) {
    return undefined;
  }
  return OPTIONS_CATEGORY_STATUS.find((item) => item.id === status);
}

export function mapPaymentStatus(status: string | undefined) {
  if (!status) {
    return undefined;
  }
  return OPTIONS_PAYMENT_STATUS.find((item) => item.id === status);
}

export function getUserFromList(
  userId: string,
  listUser: Array<{ avatar?: string | null; email?: string | null; name: string; phone_number?: string | null; id: string }>,
) {
  return listUser.find((user) => user.id === userId);
}

export function mapPackage(packageId: string, packages: GetPackagesQuery['ws_packages']) {
  return packages.find((item) => item.id === packageId);
}
