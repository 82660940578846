<script lang="ts" setup>
  import LoadingIcon from '~/_components/svg-icons/LoadingIcon.vue';

  export type ButtonColor = 'secondary' | 'primary' | 'danger' | 'blue' | 'gray';
  export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
  export type ButtonVariant = 'outlined' | 'filled' | 'empty';

  const props = withDefaults(
    defineProps<{
      loading?: boolean;
      color?: ButtonColor;
      size?: ButtonSize;
      variant?: ButtonVariant;
      renderAs?: string;
    }>(),
    {
      color: 'primary',
      variant: 'filled',
      size: 'md',
      loading: false,
    },
  );

  const DIMENSIONS: Record<ButtonSize, string> = {
    xs: 'px-2 h-[24px]',
    sm: 'px-3 h-[35px]',
    md: 'px-4 h-[40px]',
    lg: 'px-3 sm:px-6 h-[48px]',
  } as const;

  const SIZE: Record<ButtonSize, string> = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: '',
    lg: '',
  } as const;

  const FILLED = {
    default: 'disabled:pointer-events-none rounded-xl',
    primary: 'hover:bg-primary/80 text-white bg-primary disabled:bg-[#F2F4F5] disabled:text-[#99A5B2]',
    secondary: 'text-black bg-secondary hover:bg-secondary/80 disabled:bg-secondary/80',
    blue: 'text-white bg-blue-1 hover:bg-blue-1/80 disabled:bg-blue-1/80',
    gray: 'text-white bg-gray-1 hover:bg-gray-1/80 disabled:bg-gray-1/80',
    danger: 'hover:bg-[#E85047]/80 text-white bg-[#E85047] disabled:border-gray-1 disabled:bg-gray-1',
  } as const;

  const OUTLINED = {
    default: 'rounded-xl disabled:pointer-events-none border rounded-xl',
    primary: 'border-primary bg-white text-primary hover:bg-primary hover:text-white',
    secondary: 'border-gray-1 text-black hover:bg-secondary',
    blue: 'border-blue-1 text-blue-1 hover:bg-blue-1 hover:text-white',
    gray: 'border-gray-1 text-black hover:bg-gray-1',
    danger: 'border-red hover:text-white hover:bg-red text-red',
  } as const;

  const EMPTY = {
    default:
      'rounded-xl bg-transparent focus:brightness-[90%] active:brightness-[80%] disabled:pointer-events-none hover:brightness-[90%] disabled:opacity-40',
    primary: 'hover:bg-primary/80 text-white hover:text-black',
    secondary: 'hover:bg-gray-800/80',
    danger: 'hover:bg-red-500/80',
    gray: 'hover:bg-gray-3/80',
    green: 'hover:bg-gray-3/80',
    pink: 'hover:bg-gray-3/80',
  } as const;

  const VARIANT = {
    outlined: OUTLINED,
    filled: FILLED,
    empty: EMPTY,
  };
</script>

<template>
  <component
    :is="props.renderAs || 'button'"
    type="button"
    class="flex items-center justify-center gap-1 whitespace-nowrap transition-colors"
    :class="[VARIANT[variant].default, VARIANT[variant][color as keyof (typeof VARIANT)[ButtonVariant]], DIMENSIONS[size], SIZE[size]]"
  >
    <slot name="startIcon" />
    <slot />
    <slot name="endIcon" />
    <LoadingIcon v-if="loading" color="currentColor" />
  </component>
</template>

<style scoped></style>
