<script setup lang="ts"></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/ic_close_fill">
      <path
        id="Vector"
        d="M20.1807 3.40921C19.9186 3.14719 19.5631 3 19.1925 3C18.8219 3 18.4665 3.14719 18.2044 3.40921L11.7949 9.81875L5.38534 3.40921C5.12324 3.14719 4.76781 3 4.39721 3C4.02661 3 3.67118 3.14719 3.40909 3.40921C3.14707 3.6713 2.99988 4.02673 2.99988 4.39733C2.99988 4.76794 3.14707 5.12337 3.40909 5.38546L9.81863 11.795L3.40909 18.2045C3.14707 18.4666 2.99988 18.8221 2.99988 19.1927C2.99988 19.5633 3.14707 19.9187 3.40909 20.1808C3.67118 20.4428 4.02661 20.59 4.39721 20.59C4.76781 20.59 5.12324 20.4428 5.38534 20.1808L11.7949 13.7713L18.2044 20.1808C18.4665 20.4428 18.8219 20.59 19.1925 20.59C19.5631 20.59 19.9186 20.4428 20.1807 20.1808C20.4427 19.9187 20.5899 19.5633 20.5899 19.1927C20.5899 18.8221 20.4427 18.4666 20.1807 18.2045L13.7711 11.795L20.1807 5.38546C20.4427 5.12337 20.5899 4.76794 20.5899 4.39733C20.5899 4.02673 20.4427 3.6713 20.1807 3.40921Z"
        fill="black"
      />
    </g>
  </svg>
</template>

<style scoped></style>
