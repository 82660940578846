import { watchEffect } from 'vue';
import { usePackages } from '~/modules/shared/composables/usePackages.ts';
import useUserState from '~/_composables/useUserState.ts';

function useFetchPreData() {
  const { user } = useUserState();
  const { fetchPackages } = usePackages();

  watchEffect(() => {
    if (user.value?.id) {
      void fetchPackages();
    }
  });
}

export default useFetchPreData;
