import { toRef } from 'vue';
import { useUserStore } from '~/modules/shared/stores/user-store';

// wrapper layer for future customization, also to make eslint-plugin-vue to be able to check ref usage as operand
function useUserState() {
  const userStore = useUserStore();

  const isAuthenticated = toRef(userStore, 'isAuthenticated');
  const isEagerring = toRef(userStore, 'isEagerring');
  const user = toRef(userStore, 'user');
  const userPermission = toRef(userStore, 'userPermission');
  const isFetchingProfile = toRef(userStore, 'isFetchingProfile');
  const isSigningIn = toRef(userStore, 'isSigningIn');
  const isSigningOut = toRef(userStore, 'isSigningOut');
  const authError = toRef(userStore, 'authError');
  const fetchProfileError = toRef(userStore, 'fetchProfileError');

  return {
    isAuthenticated,
    isEagerring,
    user,
    isFetchingProfile,
    fetchProfileError,
    isSigningIn,
    isSigningOut,
    authError,
    userPermission,
  };
}

export default useUserState;
