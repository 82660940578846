import { acceptHMRUpdate, defineStore } from 'pinia';
import type { AllowedComponentProps, Component, VNodeProps } from 'vue';
import { markRaw } from 'vue';
import type { StyledDialogProps } from '~/_components/StyledDialog/dialog-provider.ts';

export interface DynamicDialogStoreState {
  dialogInstanceMap: Record<
    string,
    {
      component: Component;
      options: DynamicDialogOptions<any>;
    }
  >;
}

export interface DynamicDialogOptions<CompProps> {
  key: string;
  dialogProps?: StyledDialogProps;
  componentProps?: CompProps;
  onClose?: () => void;
}

type ComponentProps<C extends Component> = C extends new (...args: any) => any
  ? Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps>
  : never;

export const useDynamicDialogStore = defineStore('_dialog', {
  state(): DynamicDialogStoreState {
    return {
      dialogInstanceMap: {},
    };
  },
  actions: {
    openDialog<Comp extends Component>(component: Comp, options: DynamicDialogOptions<ComponentProps<Comp>>) {
      this.dialogInstanceMap[options.key] = {
        component: markRaw(component),
        options,
      };

      return () => {
        this.closeDialog(options.key);
      };
    },
    closeDialog(key: string) {
      delete this.dialogInstanceMap[key];
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDynamicDialogStore, import.meta.hot));
}
