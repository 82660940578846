import { safeStringify } from '~/common/json.ts';
import type { HandledError } from '~/internals/errors/base-error.ts';

import type { GraphqlErrorDetail, GraphqlErrorResponse, GraphqlResponse } from '~/packages/graphql-client/graphql-client-types.ts';

export enum HasuraErrorCode {
  INVALID_JWT = 'invalid-jwt',
  INVALID_HEADERS = 'invalid-headers',
  TOKEN_EXPIRED = 'token-expired',
  ACCESS_DENIED = 'access-denied',
  VALIDATION_FAILED = 'validation-failed',
  DATA_EXCEPTION = 'data-exception',
  CONSTRAINT_VIOLATION = 'constraint-violation',
  PARSE_FAILED = 'parse-failed',
  POSTGRES_ERROR = 'postgres-error',
  POST_VERIFY_NOT_PENDING = 'ErrHouseCategoryDoesNotPending',
  ADD_CATEGORY_PERMISSION_DENIED = 'ErrPermissionDenied',
  POST_INVALID_CATEGORY_VERIFIED = 'ErrCannotTogetherWithHouseOwner',
  POST_INVALID_CATEGORY_OWNED = 'ErrCannotTogetherWithHouseVerified',
  POST_VERIFIED_EXCEED_ACCOUNT = 'ErrOutOfPostHouseVerified',
}

export function isGraphqlError(response: GraphqlResponse): response is GraphqlErrorResponse {
  return Array.isArray((response as any).errors);
}

export function isHasuraTokenExpiredError(errors: GraphqlErrorDetail[]) {
  return (
    Array.isArray(errors) &&
    errors.some((error) => {
      return error.extensions.code === HasuraErrorCode.INVALID_JWT || error.extensions.code === HasuraErrorCode.TOKEN_EXPIRED;
    })
  );
}

export class HasuraError extends Error implements HandledError {
  public extensions: GraphqlErrorDetail['extensions'];
  public query;

  constructor(options: { message?: string; query?: string; extensions: GraphqlErrorDetail['extensions'] }) {
    super(options.message || 'Unknown Error');
    this.extensions = options.extensions;
    this.query = options.query;
  }

  toString() {
    return safeStringify({
      message: this.message,
      extensions: this.extensions,
      query: this.query,
    });
  }

  _handle() {
    switch (this.extensions.code) {
      case HasuraErrorCode.INVALID_JWT:
      case HasuraErrorCode.TOKEN_EXPIRED:
      case HasuraErrorCode.ACCESS_DENIED:
        return 'Không có quyền truy cập';
      case HasuraErrorCode.VALIDATION_FAILED:
      case HasuraErrorCode.DATA_EXCEPTION:
      case HasuraErrorCode.INVALID_HEADERS:
        return 'Dữ liệu không hợp lệ';
      case HasuraErrorCode.CONSTRAINT_VIOLATION:
      case HasuraErrorCode.PARSE_FAILED:
        return 'Lỗi hệ thống';
      case HasuraErrorCode.POSTGRES_ERROR:
        return 'Lỗi cơ sở dữ liệu';
      case HasuraErrorCode.POST_VERIFY_NOT_PENDING:
        return 'Bài đăng đã được duyệt hoặc từ chối';
      case HasuraErrorCode.ADD_CATEGORY_PERMISSION_DENIED:
        return 'Không có quyền thêm danh mục';
      case HasuraErrorCode.POST_INVALID_CATEGORY_VERIFIED:
        return 'Bài đăng đã thuộc nhãn chính chủ';
      case HasuraErrorCode.POST_INVALID_CATEGORY_OWNED:
        return 'Bài đăng đã thuộc nhãn xác thực';
      case HasuraErrorCode.POST_VERIFIED_EXCEED_ACCOUNT:
        return 'Tài khoản người dùng đã hết lượt đăng tin xác thực';
    }
    return 'Lỗi không xác định';
  }
}
