function isSafeHost(url: string, safeOrigins: string[]) {
  const parsed = new URL(url);

  return safeOrigins.includes(parsed.host);
}

function isAValidUrl(url: string) {
  // if redirectUrl is a valid url, we should not redirect to prevent `open redirect attacks`
  try {
    const _ = new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function isSafeRedirect(redirectUrl: string, safeOrigins: string[]) {
  if (!isAValidUrl(redirectUrl)) {
    // only a path, safe
    return true;
  } else {
    // if redirectUrl is this host, allow redirect
    if (isSafeHost(redirectUrl, safeOrigins)) {
      return true;
    }

    // do nothing
  }
}
