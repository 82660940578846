import useLogger from '~/_composables/useLogger.ts';
import { GetPackagesDocument } from '~/api/user.generated.ts';
import { PACKAGE_ID } from '~/common/constants.ts';
import { mapPackage } from '~/common/utils.ts';
import { clientHasura } from '~/internals/hasura/client-hasura.ts';
import { usePackagesStore } from '~/modules/shared/stores/packages-store.ts';

export function usePackages() {
  const packagesStore = usePackagesStore();
  const logger = useLogger();

  const fetchPackages = async () => {
    try {
      packagesStore.isPackagesLoading = true;

      const resp = await clientHasura.request(GetPackagesDocument);
      packagesStore.listPackages = resp.ws_packages;
      packagesStore.packagePost = mapPackage(PACKAGE_ID.ghtt, resp.ws_packages) || null;
      packagesStore.packageRenew = mapPackage(PACKAGE_ID.lmt, resp.ws_packages) || null;
      packagesStore.packageHot = mapPackage(PACKAGE_ID.vt_hot, resp.ws_packages) || null;
      packagesStore.packageVip = mapPackage(PACKAGE_ID.nc_vip, resp.ws_packages) || null;
      packagesStore.labelHot = mapPackage(PACKAGE_ID.lb_hot, resp.ws_packages) || null;
      packagesStore.labelVerify = mapPackage(PACKAGE_ID.lb_xtt, resp.ws_packages) || null;
      packagesStore.labelHost = mapPackage(PACKAGE_ID.tcc, resp.ws_packages) || null;
    } catch (e) {
      // noop
      logger.error('Cannot get packages', e);
      packagesStore.fetchPackagesError = e as Error;
    } finally {
      packagesStore.isPackagesLoading = false;
    }
  };

  return {
    fetchPackages,
  };
}
