import jwtDecode from 'jwt-decode';

interface IToken {
  claims: {
    roles: string[];
  };
}

export function isAdmin(token: string) {
  const parsed = jwtDecode<IToken>(token);
  // eslint-disable-next-line ts/no-unnecessary-condition
  if (!parsed || !parsed.claims || !parsed.claims.roles) {
    return false;
  }

  return (
    parsed.claims.roles.includes('system-admin') || parsed.claims.roles.includes('root') || parsed.claims.roles.includes('district-admin')
  );
}
