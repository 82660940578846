<script setup lang="ts"></script>

<template>
  <svg width="146" height="160" viewBox="0 0 146 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M118.85 96.9731H109.612V91.4714C109.612 89.6367 108.112 88.1365 106.278 88.1365H92.9382C91.1047 88.1365 89.6033 89.6367 89.6033 91.4714V96.9731H80.3662L84.2017 140.458H114.982L118.85 96.9731ZM105.61 72.0623C126.285 74.9639 142.192 92.7043 142.192 114.18C142.192 137.657 123.151 156.698 99.6741 156.698C83.5352 156.698 69.4617 147.695 62.2925 134.422C59.0247 128.387 57.157 121.484 57.157 114.147C57.157 90.6707 76.1987 71.6289 99.6741 71.6289C101.676 71.6619 103.644 71.7961 105.61 72.0623Z"
      fill="#FEDB41"
    />
    <path d="M118.85 96.9746L115.015 140.46H84.2346L80.3662 96.9746H118.85Z" fill="#FEDB41" />
    <path
      d="M109.612 91.4717V96.9746H89.6045V91.4717C89.6045 89.6382 91.1047 88.1367 92.9395 88.1367H106.278C108.112 88.1379 109.612 89.6382 109.612 91.4717Z"
      fill="#FEDB41"
    />
    <path d="M71.6968 3.36719L105.61 38.9824H83.0347C76.7651 38.9824 71.6968 33.9128 71.6968 27.6445V3.36719Z" fill="#6DC87D" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M109.045 38.9832V69.2627C118.716 71.2634 127.587 76.3989 134.222 83.9355C141.559 92.3047 145.527 103.01 145.527 114.148C145.527 139.458 124.952 160 99.6753 160C83.501 160 68.6279 151.73 60.3589 137.725H17.8748C7.93701 137.725 0 129.755 0 119.817V18.0408C0 8.10303 7.93701 0 17.8748 0H71.6968C72.5964 0 73.4973 0.367432 74.1309 1.03394L108.079 36.6821C108.645 37.3157 109.045 38.1152 109.045 38.9832ZM97.8076 35.6812L75.0317 11.7053H75.0305V27.6453C75.0305 32.0471 78.6328 35.6812 83.0347 35.6812H97.8076ZM6.66992 18.0408V119.817C6.66992 126.052 11.6382 131.055 17.8748 131.055H57.0239C54.8889 125.719 53.7891 119.95 53.7891 114.115C53.7891 110.413 54.2224 106.711 55.0562 103.376H26.9446C25.1099 103.376 23.6096 101.876 23.6096 100.042C23.6096 98.208 25.1111 96.7078 26.9446 96.7078H57.2241C58.7573 93.0396 60.7251 89.7046 63.0933 86.3696H26.9446C25.1099 86.3696 23.6096 84.8694 23.6096 83.0347C23.6096 81.2 25.1111 79.6997 26.9446 79.6997H69.2957C77.3657 72.6965 88.0701 68.2288 99.7083 68.2288C100.178 68.2288 100.628 68.2678 101.101 68.3088C101.501 68.3435 101.918 68.3797 102.377 68.3948V42.3511H83.0347C74.9316 42.3511 68.3618 35.7483 68.3618 27.6453V6.66992H17.8748C11.6382 6.66992 6.66992 11.7712 6.66992 18.0408ZM65.1941 132.855C72.063 145.494 85.3027 153.364 99.6753 153.364C121.284 153.364 138.857 135.79 138.824 114.181C138.824 104.644 135.389 95.4736 129.12 88.3374C122.917 81.2671 114.381 76.665 105.11 75.365C103.309 75.1318 101.476 74.9976 99.6411 74.9976C78.0322 74.9976 60.459 92.572 60.459 114.181C60.459 120.717 62.0923 127.153 65.1941 132.855ZM60.725 69.6961H26.9445C25.111 69.6961 23.6096 68.1946 23.6096 66.3611C23.6096 64.5264 25.111 63.0262 26.9445 63.0262H60.725C62.5598 63.0262 64.06 64.5264 64.06 66.3611C64.06 68.1946 62.5598 69.6961 60.725 69.6961Z"
      fill="black"
    />
    <path
      d="M125.786 93.707H113.047V91.4731C113.047 87.8049 109.946 84.7031 106.278 84.7031H92.9382C89.27 84.7031 86.3696 87.8049 86.3696 91.4731V93.707H73.4644C71.6296 93.707 70.1294 95.2073 70.1294 97.042C70.1294 98.8755 71.6296 100.377 73.4644 100.377H77.3328L80.9009 140.76C81.0669 142.494 82.5012 143.728 84.2346 143.728H115.015C116.749 143.728 118.182 142.461 118.35 140.76L121.918 100.377H125.786C127.62 100.377 129.121 98.8755 129.121 97.042C129.121 95.2073 127.62 93.707 125.786 93.707ZM93.0383 91.3718H106.377V93.707H93.0383V91.3718ZM111.947 137.058H87.2693L84.0344 100.376H115.215L111.947 137.058Z"
      fill="black"
    />
  </svg>
</template>

<style scoped></style>
