<script setup lang="ts">
  import { ref } from 'vue';
  import { useDynamicDialogStore } from '~/_components/StyledDialog/dynamic-dialog-store.ts';
  import StyledDialog from '~/_components/StyledDialog/StyledDialog.vue';

  const dynamicDialogStore = useDynamicDialogStore();
  const visible = ref(true);

  function onDialogClose(key: string) {
    dynamicDialogStore.dialogInstanceMap[key].options.onClose?.();
    dynamicDialogStore.closeDialog(key);
    visible.value = true;
  }
</script>

<template>
  <template v-for="(instance, key) in dynamicDialogStore.dialogInstanceMap" :key="key">
    <StyledDialog v-model:visible="visible" v-bind="instance.options.dialogProps" @close="onDialogClose(key)" @hide="onDialogClose(key)">
      <component :is="instance.component" v-bind="instance.options.componentProps" />
    </StyledDialog>
  </template>
</template>

<style scoped></style>
