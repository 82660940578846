export enum RoomTypes {
  HOSTEL = 'hostel',
  WHOLE_HOUSE = 'whole_house',
  SERVICED_APARTMENT = 'serviced_apartment',
}

export const CATEGORIES_HOUSE = {
  hot_house: 'hot_house',
  bedsit_new_update: 'bedsit_new_update',
  apartment_new_update: 'apartment_new_update',
  project_company: 'project_company',
  house_verified: 'house_verified',
  house_owners: 'house_owners',
};

export const LIST_CATEGORIES_CAN_EDIT = [CATEGORIES_HOUSE.bedsit_new_update, CATEGORIES_HOUSE.apartment_new_update];

export interface ICategoryHouse {
  name: string;
  slug: string;
  id: keyof typeof CATEGORIES_HOUSE;
}

export const USER_ROLES = {
  user: 'user',
  host: 'host',
  sale: 'sale',
  saleHost: 'sale-host',
};

export const LIST_ROLES = [
  // {
  //   name: 'Người dùng',
  //   id: USER_ROLES.sale,
  // },
  {
    name: 'Chuyên viên',
    id: USER_ROLES.sale,
  },
  {
    name: 'Chủ nhà',
    id: USER_ROLES.saleHost,
  },
];

export const LIST_CATEGORIES_HOUSE: ICategoryHouse[] = [
  // {
  //   name: 'Phòng HOT',
  //   slug: 'phong-hot',
  //   id: 'hot_house',
  // },
  {
    name: 'Phòng trọ mới cập nhật',
    slug: 'phong-tro-moi-cap-nhat',
    id: 'bedsit_new_update',
  },
  {
    name: 'Căn hộ mới cập nhật',
    slug: 'can-ho-moi-cap-nhat',
    id: 'apartment_new_update',
  },
  {
    name: 'Tin đăng đã xác thực',
    slug: 'tin-dang-xac-thuc',
    id: 'house_verified',
  },
  {
    name: 'Tin đăng chính chủ',
    slug: 'tin-chu-nha-dang',
    id: 'house_owners',
  },
];

export const LIMIT_IMAGE_SIZE = 10;
export const LIMIT_TOTAL_IMAGE_SIZE = 200;
export const LIMIT_VIDEO_SIZE = 500;

export const OPTION_ANY = 'any';

export const LIST_DIRECTIONS = [
  {
    name: 'Bắc',
    id: 'north',
  },
  {
    name: 'Nam',
    id: 'south',
  },
  {
    name: 'Đông',
    id: 'east',
  },
  {
    name: 'Tây',
    id: 'west',
  },
  {
    name: 'Đông Bắc',
    id: 'northeast',
  },
  {
    name: 'Tây Bắc',
    id: 'northwest',
  },
  {
    name: 'Đông Nam',
    id: 'southeast',
  },
  {
    name: 'Tây Nam',
    id: 'southwest',
  },
];

export const OPTIONS_AREA = [
  {
    name: 'Bất kỳ',
    query: undefined,
    id: OPTION_ANY,
  },
  {
    name: 'Dưới 20m2',
    query: [
      {
        area: {
          _lte: 20,
        },
      },
    ],
    id: 'duoi-20-m2',
  },
  {
    name: '',
    query: [
      {
        area: {
          _gte: 20,
        },
      },
      {
        area: {
          _lte: 30,
        },
      },
    ],
    id: '20-30-m2',
  },
  {
    name: 'Từ 30 - 50m2',
    query: [
      {
        area: {
          _gte: 30,
        },
      },
      {
        area: {
          _lte: 50,
        },
      },
    ],
    id: '30-to-50-m2',
  },
  {
    name: 'Từ 50 - 70m2',
    query: [
      {
        area: {
          _gte: 50,
        },
      },
      {
        area: {
          _lte: 70,
        },
      },
    ],
    id: '50-to-70-m2',
  },
  {
    name: 'Từ 70 - 90m2',
    query: [
      {
        area: {
          _gte: 70,
        },
      },
      {
        area: {
          _lte: 90,
        },
      },
    ],
    id: '70-to-90-m2',
  },
  {
    name: 'Trên 90m2',
    query: [
      {
        area: {
          _gte: 90,
        },
      },
    ],
    id: 'above-90-m2',
  },
];

export const OPTIONS_PRICES = [
  {
    name: 'Bất kỳ',
    query: undefined,
    id: OPTION_ANY,
  },
  {
    name: 'Dưới 1 triệu',
    query: [
      {
        price: {
          _lte: 1_000_000,
        },
      },
    ],
    id: 'under-1tr',
  },
  {
    name: 'Từ 1 - 3 triệu',
    query: [
      {
        price: {
          _gte: 1_000_000,
        },
      },
      {
        price: {
          _lte: 3_000_000,
        },
      },
    ],
    id: '1-3tr',
  },
  {
    name: 'Từ 3 - 5 triệu',
    query: [
      {
        price: {
          _gte: 3_000_000,
        },
      },
      {
        price: {
          _lte: 5_000_000,
        },
      },
    ],
    id: '3-5tr',
  },
  {
    name: 'Từ 5 - 7 triệu',
    query: [
      {
        price: {
          _gte: 5_000_000,
        },
      },
      {
        price: {
          _lte: 7_000_000,
        },
      },
    ],
    id: '5-7tr',
  },
  {
    name: 'Từ 7 - 10 triệu',
    query: [
      {
        price: {
          _gte: 7_000_000,
        },
      },
      {
        price: {
          _lte: 10_000_000,
        },
      },
    ],
    id: '7-10tr',
  },
  {
    name: 'Từ 10 - 15 triệu',
    query: [
      {
        price: {
          _gte: 10_000_000,
        },
      },
      {
        price: {
          _lte: 15_000_000,
        },
      },
    ],
    id: '10-15tr',
  },
  {
    name: 'Trên 10 triệu',
    query: [
      {
        price: {
          _gte: 10_000_000,
        },
      },
    ],
    id: 'above-10tr',
  },
];

export const OPTIONS_SORT = [
  {
    name: 'Mặc định',
    id: 'default',
  },
  {
    name: 'Bài mới nhất',
    id: 'bai-moi-nhat',
  },
  {
    name: 'Giá tăng dần',
    id: 'gia-tang',
  },
  {
    name: 'Giá giảm dần',
    id: 'gia-giam',
  },
] as const;

export const OPTION_SORT_SPECIALIZED = {
  name: 'Chuyên trang',
  id: 'chuyen-trang',
} as const;

export type ISort = (typeof OPTIONS_SORT)[number]['id'] | (typeof OPTION_SORT_SPECIALIZED)['id'];

// SG
export const SEARCH_DISTRICT_DEFAULT = '202';

export const LIST_DISTRICT_DEFAULT = [
  {
    id: '1442',
    name: 'Quận 1',
  },
  {
    id: '1443',
    name: 'Quận 2',
  },
  {
    id: '1444',
    name: 'Quận 3',
  },
  {
    id: '1446',
    name: 'Quận 4',
  },
  {
    id: '1447',
    name: 'Quận 5',
  },
  {
    id: '1448',
    name: 'Quận 6',
  },
  {
    id: '1449',
    name: 'Quận 7',
  },
  {
    id: '1450',
    name: 'Quận 8',
  },
  {
    id: '1451',
    name: 'Quận 9',
  },
  {
    id: '1452',
    name: 'Quận 10',
  },
  {
    id: '1453',
    name: 'Quận 11',
  },
  {
    id: '1454',
    name: 'Quận 12',
  },
  {
    id: '1462',
    name: 'Quận Bình Thạnh',
  },
  {
    id: '1457',
    name: 'Quận Phú Nhuận',
  },
  {
    id: '1455',
    name: 'Quận Tân Bình',
  },
  {
    id: '2090',
    name: 'Huyện Cần Giờ',
  },
  {
    id: '1534',
    name: 'Huyện Nhà Bè',
  },
  {
    id: '1533',
    name: 'Huyện Bình Chánh',
  },
  {
    id: '1463',
    name: 'Quận Thủ Đức',
  },

  {
    id: '1461',
    name: 'Quận Gò Vấp',
  },
  {
    id: '1460',
    name: 'Huyện Củ Chi',
  },
  {
    id: '1459',
    name: 'Huyện Hóc Môn',
  },
  {
    id: '1458',
    name: 'Quận Bình Tân',
  },

  {
    id: '1456',
    name: 'Quận Tân Phú',
  },
];

export const HOT_DISTRICT = ['1442', '1443', '1444', '1462', '1457', '1455'];

export const HOUSE_TYPES = [
  {
    id: 'condominium',
    name: 'Chung cư',
  },
  {
    id: 'sleep_box',
    name: 'Sleep box',
  },
  {
    id: 'bedsit',
    name: 'Phòng trọ',
  },
  {
    id: 'duplex',
    name: 'Căn hộ Duplex',
  },
  {
    id: 'studio',
    name: 'Căn hộ Studio',
  },
  {
    id: 'house_1_bedroom',
    name: 'Căn hộ 1 phòng ngủ',
  },
  {
    id: 'house_2_bedroom',
    name: 'Căn hộ 2 phòng ngủ',
  },
  {
    id: 'house_3_bedroom',
    name: 'Căn hộ 3 phòng ngủ',
  },
  {
    id: 'penthouse',
    name: 'Penthouse',
  },
];

export const MAP_CATEGORY_WITH_ROOM_TYPE = {
  sleep_box: CATEGORIES_HOUSE.bedsit_new_update,
  bedsit: CATEGORIES_HOUSE.bedsit_new_update,
  duplex: CATEGORIES_HOUSE.apartment_new_update,
  studio: CATEGORIES_HOUSE.apartment_new_update,
  house_1_bedroom: CATEGORIES_HOUSE.apartment_new_update,
  house_2_bedroom: CATEGORIES_HOUSE.apartment_new_update,
  house_3_bedroom: CATEGORIES_HOUSE.apartment_new_update,
  penthouse: CATEGORIES_HOUSE.apartment_new_update,
} as const;

export const STATUS_POST = {
  posted: 'posted',
  hidden: 'hidden',
} as const;

export const LIST_PROJECT_DISTRICT_HOT = LIST_DISTRICT_DEFAULT.filter((district) => {
  return HOT_DISTRICT.includes(district.id);
});

export const PAYMENT_CYCLE = {
  month: 'month',
  day: 'day',
  week: 'week',
  quarter: 'quarter',
  year: 'year',
} as const;
export type PAYMENT_CYCLE_KEY = (typeof PAYMENT_CYCLE)[keyof typeof PAYMENT_CYCLE];

export const LIST_PAYMENT_CYCLE = [
  {
    id: PAYMENT_CYCLE.month,
    name: 'Tháng',
  },
  {
    id: PAYMENT_CYCLE.day,
    name: 'Ngày',
  },
  {
    id: PAYMENT_CYCLE.week,
    name: 'Tuần',
  },
  {
    id: PAYMENT_CYCLE.quarter,
    name: 'Quý',
  },
  {
    id: PAYMENT_CYCLE.year,
    name: 'Năm',
  },
];

export const PAYMENT_CYCLE_FORMAT = {
  month: 'tháng',
  day: 'ngày',
  week: 'tuần',
  quarter: 'quý',
  year: 'năm',
};

// export const LIST_CATEGORIES = [
//   {
//     id: CATEGORIES_HOUSE.hot_house,
//     name: 'Phòng HOT',
//   },
//   {
//     id: CATEGORIES_HOUSE.apartment_new_update,
//     name: 'Căn hộ mới cập nhật',
//   },
//   {
//     id: CATEGORIES_HOUSE.bedsit_new_update,
//     name: 'Phòng trọ mới cập nhật',
//   },
//   {
//     id: CATEGORIES_HOUSE.project_company,
//     name: 'Các dự án mới từ công ty',
//   },
// ];

export const OPTIONS_USERS_STATUS = [
  {
    name: 'ACTIVE',
    id: 'ACTIVE',
  },
  {
    name: 'INACTIVE',
    id: 'INACTIVE',
  },
];

export const CATEGORY_STATUS = {
  posted: 'posted',
  pending: 'pending',
  draft: 'draft',
  expired: 'expired',
  rejected: 'rejected',
};

export const OPTIONS_CATEGORY_STATUS = [
  {
    name: 'Đã xác thực',
    id: 'posted',
    color: 'text-success',
  },
  {
    name: 'Chờ xác thực',
    id: 'pending',
    color: 'text-warning',
  },
  {
    name: 'Đã từ chối',
    id: 'rejected',
    color: 'text-danger',
  },
];

export const OPTIONS_PAYMENT_STATUS = [
  {
    name: 'Đã duyệt',
    id: 'approved',
    color: 'text-success',
  },
  {
    name: 'Chờ duyệt',
    id: 'pending',
    color: 'text-warning',
  },
  {
    name: 'Đã từ chối',
    id: 'rejected',
    color: 'text-danger',
  },
];

export const OPTIONS_HOT_HOUSE_STATUS = [
  {
    name: 'Đang hiển thị',
    id: 'active',
    color: 'text-success',
  },
  {
    name: 'Hết hạn',
    id: 'expire',
    color: 'text-warning',
  },
];

export const PAYMENT_STATUS = {
  posted: 'posted',
  pending: 'pending',
  draft: 'draft',
  expired: 'expired',
  rejected: 'rejected',
};

export const PACKAGE_ID = {
  deposited_money: 'deposited_money',
  vt_hot: 'vt_hot',
  lb_hot: 'lb_hot',
  nc_vip: 'nc_vip',
  lmt: 'lmt',
  ghtt: 'ghtt',
  ttt: 'ttt',
  tcc: 'tcc',
  lb_xtt: 'lb_xtt',
};

export const NAM_A_BANK = {
  accountFullName: 'TRAN DO AN DUY',
  accountNumber: '0343136884',
  bankName: 'NAM A BANK',
  bankCode: 'NAB',
  bankIcon: '/images/banks/namabank.png',
};

export const VCB_BANK = {
  accountFullName: 'Tran Do An Duy',
  accountNumber: '0511000443826',
  bankName: 'Vietcombank',
  bankCode: 'VCB',
  bankIcon: '/images/banks/vcb.png',
};

export const BANKS = [NAM_A_BANK, VCB_BANK];
