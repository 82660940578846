import { createGraphqlClient } from '~/packages/graphql-client/create-graphql-client.ts';
import { HasuraError } from '~/internals/errors/hasura-error.ts';
import { createHttpClient } from '~/packages/http-client/create-http-client.ts';
import { API_BASE_URL } from '~/common/config.ts';

const httpClient = createHttpClient(
  {
    baseURL: API_BASE_URL,
    timeout: 30000,
  },
  {
    method: 'POST',
    defaultHeaders: {
      'content-type': 'application/json',
    },
  },
);

export const clientHasuraNoAuth = createGraphqlClient(httpClient, {
  affix: '/v1/graphql',
  onError: (errResp, query) => {
    const [error] = errResp.errors;
    throw new HasuraError({
      message: error.message,
      extensions: error.extensions,
      query,
    });
  },
});
