import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { print, visit } from 'graphql';

import type { DocumentNode, OperationDefinitionNode } from 'graphql';
import type { createHttpClient } from '~/packages/http-client/create-http-client.ts';
import type {
  GraphqlClientOptions,
  GraphqlErrorResponse,
  GraphqlResponse,
  GraphqlSuccessResponse,
} from '~/packages/graphql-client/graphql-client-types.ts';

export function isGraphqlError<T>(resp: T | unknown): resp is GraphqlErrorResponse {
  return Array.isArray((resp as any)?.errors);
}

export function getOperationName(query: DocumentNode) {
  let operationName;
  visit(query, {
    OperationDefinition(node: OperationDefinitionNode) {
      operationName = node.name?.value;
    },
  });
  return operationName;
}

export function createGraphqlClient(
  httpClient: ReturnType<typeof createHttpClient>,
  { affix = '/v1/graphql', onError }: GraphqlClientOptions,
) {
  return {
    async request<TData = any, TVariables = Record<string, any>>(
      operation: TypedDocumentNode<TData, TVariables>,
      variables?: TVariables,
    ): Promise<TData> {
      const query = print(operation);

      const res = await httpClient.request<GraphqlResponse<TData>>({
        url: affix,
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        data: { query, variables },
      });

      if (isGraphqlError(res)) {
        onError?.(res, query);
      }

      return (res as GraphqlSuccessResponse<TData>).data;
    },
  };
}
