import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/buildhome/repo/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_sync_client_cQOPqId9wD from "/opt/buildhome/repo/src/plugins/auth-sync.client.ts";
import bugsnag_client_P6PdWUa9Zq from "/opt/buildhome/repo/src/plugins/bugsnag.client.ts";
import prime_vue_z3vbDoNiWU from "/opt/buildhome/repo/src/plugins/prime-vue.ts";
import social_auth_client_oilx79xljf from "/opt/buildhome/repo/src/plugins/social-auth.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  chunk_reload_client_UciE0i6zes,
  auth_sync_client_cQOPqId9wD,
  bugsnag_client_P6PdWUa9Zq,
  prime_vue_z3vbDoNiWU,
  social_auth_client_oilx79xljf
]