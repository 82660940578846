// @ts-nocheck
// import PrimeVue from 'primevue/config';
// import ToastService from 'primevue/toastservice';
// import Tailwind from 'primevue/passthrough/tailwind';
// import ConfirmationService from 'primevue/confirmationservice';
import StyleClass from 'primevue/styleclass';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(StyleClass);
  // vueApp.use(PrimeVue, {
  //   unstyled: true,
  //   pt: {
  //     ...Tailwind,
  //     card: {
  //       ...Tailwind.card,
  //       root: {
  //         class: [
  //           'rounded-3xl bg-white',
  //           // 'dark:bg-gray-900 dark:text-white'
  //         ],
  //       },
  //       body: 'p-0',
  //       content: 'py-0',
  //     },
  //     checkbox: {
  //       ...Tailwind.checkbox,
  //       input: ({ props, context }) => ({
  //         class: [
  //           'flex items-center justify-center',
  //           'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
  //           {
  //             'border-gray-300 bg-white dark:border-blue-900/40 dark:bg-gray-900': !context.checked,
  //             'border-primary bg-primary dark:border-blue-400 dark:bg-blue-400': context.checked,
  //           },
  //           {
  //             'hover:border-primary dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]':
  //               !props.disabled,
  //             'cursor-default opacity-60': props.disabled,
  //           },
  //         ],
  //       }),
  //     },
  //     menu: {
  //       ...Tailwind.menu,
  //       root: 'bg-white dark:bg-gray-900 menu-shadow dark:text-white/80 border border-menu-border dark:border-blue-900/40 rounded-lg',
  //       menu: {
  //         class: ['overflow-hidden rounded-[6px]'],
  //       },
  //       content: ({ context }) => ({
  //         class: [
  //           'text-gray-700 dark:text-white/80 transition-shadow duration-200 rounded-none px-3',
  //           'hover:text-gray-700 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80', // Hover
  //           {
  //             '': context.focused,
  //           },
  //         ],
  //       }),
  //     },
  //     dropdown: {
  //       ...Tailwind.dropdown,
  //       root: ({ props }) => ({
  //         class: [
  //           'cursor-pointer relative select-none px-4 flex items-center',
  //           'bg-white border transition-colors duration-200 ease-in-out rounded-xl',
  //           'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300',
  //           'w-full',
  //           'hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
  //           {
  //             'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
  //           },
  //         ],
  //       }),
  //       input: ({ props }) => ({
  //         class: [
  //           'cursor-pointer  text-menu-input-text text-sm block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
  //           'bg-transparent border-0',
  //           'dark:text-white/80',
  //           'transition duration-200 bg-transparent rounded appearance-none text-base',
  //           'focus:outline-none focus:shadow-none',
  //           { 'pr-7': props.showClear },
  //         ],
  //       }),
  //       list: 'list-none m-0 py-1 menu-content',
  //       item: () => ({
  //         class: [
  //           'relative text-sm text-black w-full',
  //           // 'm-0 px-2 py-2 mx-1 border-b border-menu-border transition-shadow duration-200 rounded-none',
  //           // 'dark:text-white/80 dark:hover:bg-gray-800',
  //           // 'hover:text-gray-700 hover:bg-gray-200',
  //           {
  //             // 'bg-red': index === 0,
  //             // 'text-gray-700': !context.focused && !context.selected,
  //             // 'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.selected,
  //             // 'bg-blue-400 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.selected,
  //             // 'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.selected,
  //           },
  //         ],
  //       }),
  //       itemgroup: {
  //         class: ['m-0 px-2 mx-1 ', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
  //       },
  //       header: {
  //         class: [
  //           'border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg',
  //           'dark:bg-gray-800 dark:text-white/80 dark:border-blue-900/40',
  //         ],
  //       },
  //       trigger: {
  //         class: ['bg-transparent text-gray-500 w-6 rounded-tr-lg rounded-br-lg'],
  //       },
  //     },
  //     paginator: {
  //       ...Tailwind.paginator,
  //       root: {
  //         class: [
  //           'flex items-center justify-center flex-wrap',
  //           'bg-white',
  //           'dark:bg-gray-900 dark:text-white/60 dark:border-blue-900/40', // Dark Mode
  //         ],
  //       },
  //       previouspagebutton: ({ context }) => ({
  //         class: [
  //           'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
  //           'border-0 text-gray-500 min-w-[1.5rem] h-6 mr-3 rounded-md',
  //           'transition duration-200',
  //           'dark:text-white',
  //           {
  //             'cursor-default pointer-events-none opacity-20': context.disabled,
  //           },
  //         ],
  //       }),
  //       nextpagebutton: ({ context }) => ({
  //         class: [
  //           'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
  //           'border-0 text-gray-500 min-w-[1.5rem] h-6 ml-3 rounded-md',
  //           'transition duration-200',
  //           'dark:text-white', // Dark Mode
  //           {
  //             'cursor-default pointer-events-none opacity-20': context.disabled,
  //           },
  //         ],
  //       }),
  //       pagebutton: ({ context }) => ({
  //         class: [
  //           'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
  //           'min-w-[1.5rem] h-6 rounded-full mx-1',
  //           'text-sm font-bold text-black border border-pagination-border-page',
  //           'transition duration-200',
  //           'dark:border-blue-300 dark:text-white', // Dark Mode
  //           {
  //             'border-pagination-border-page-active text-primary dark:bg-blue-300': context.active,
  //           },
  //         ],
  //       }),
  //     },
  //     breadcrumb: {
  //       ...Tailwind.breadcrumb,
  //       root: {
  //         class: [''],
  //       },
  //       separator: {
  //         class: ['mx-1 dark:text-white/70', 'flex items-center'],
  //       },
  //     },
  //     skeleton: {
  //       root: ({ props }) => ({
  //         class: [
  //           'overflow-hidden',
  //           'bg-gray-300 dark:bg-gray-800',
  //           'after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:content after:w-full after:h-full after:bg-blue-400 after:left-full after:transform after:translate-x-full after:z-10 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent animate-pulse',
  //           {
  //             'rounded-md': props.shape !== 'circle',
  //             'rounded-full': props.shape === 'circle',
  //           },
  //         ],
  //       }),
  //     },
  //     tabview: {
  //       ...Tailwind.tabview,
  //       nav: {
  //         class: [
  //           'flex flex-1 list-none m-0 p-0',
  //           'bg-white border border-gray-300 border-0',
  //           'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 ',
  //         ], // Flex, list, margin, padding, and border styles.
  //       },
  //       tabpanel: {
  //         ...Tailwind.tabview.tabpanel,
  //         headerAction: ({ parent, context }) => ({
  //           class: [
  //             'text-sm cursor-pointer items-center cursor-pointer flex overflow-hidden relative select-none text-decoration-none user-select-none', // Flex and overflow styles.
  //             'p-[14px] !mb-0 font-bold rounded-t-lg transition-shadow duration-200 m-0', // Border, padding, font, and transition styles.
  //             'transition-colors duration-200', // Transition duration style.
  //             {
  //               'bg-white text-secondary-text hover:bg-white hover:border-gray-400 hover:text-gray-600 dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 dark:hover:bg-gray-800/80':
  //                 parent.state.d_activeIndex !== context.index, // Condition-based hover styles.
  //               'bg-white border-b-2 border-primary text-primary dark:bg-gray-900 dark:border-blue-300 dark:text-blue-300':
  //                 parent.state.d_activeIndex === context.index, // Condition-based active styles.
  //             },
  //           ],
  //           style: 'margin-bottom:-2px', // Negative margin style.
  //         }),
  //       },
  //     },
  //     textarea: {
  //       root: ({ context }) => ({
  //         class: [
  //           'm-0',
  //           'placeholder-text-placeholder text-black dark:text-white/80 bg-white dark:bg-gray-900 p-3 border dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-xl',
  //           'hover:border-primary focus:outline-none focus:border-primary ',
  //           {
  //             'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
  //           },
  //         ],
  //       }),
  //     },
  //     inputtext: {
  //       root: ({ props, context }) => ({
  //         class: [
  //           'm-0',
  //           'text-black dark:text-white/80 bg-white dark:bg-gray-900 transition-colors duration-200 appearance-none rounded-xl',
  //           {
  //             'focus:outline-none focus:outline-offset-0': !context.disabled,
  //             'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
  //           },
  //           {
  //             'text-lg px-4 py-4': props.size === 'large',
  //             'text-xs px-2 py-2': props.size === 'small',
  //             'p-3 text-base': props.size === null,
  //           },
  //         ],
  //       }),
  //     },
  //   },
  // });
  // vueApp.use(ToastService);
  // vueApp.use(ConfirmationService);
  //
  // vueApp.directive('styleclass', StyleClass);
});
