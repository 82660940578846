import type { FirebaseApp } from '@firebase/app';
import type { Auth as IFirebaseAuth, User, UserCredential } from '@firebase/auth';
import {
  GoogleAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updatePassword,
  updatePhoneNumber,
} from '@firebase/auth';
import type { AuthHook, BaseProvider } from '~/packages/auth-sdk/auth-sdk-types';
import { isSafeRedirect } from '~/packages/auth-sdk/auth-sdk-utils.ts';

export interface FirebaseProviderOptions {
  logger?: Logger;
  getApp: () => FirebaseApp;
  // will automatically redirect to url start with safeOrigin, other origin will be ignored
  safeOrigins?: string[]; // default to this site origin
  hooks: AuthHook;
}

export interface Logger {
  log: (...args: any[]) => void;
  error: (...args: any[]) => void;
  warn: (...args: any[]) => void;
}

export class FirebaseProvider implements BaseProvider {
  authInstance: IFirebaseAuth | null = null; // will be set in _getInstance
  options: FirebaseProviderOptions;
  key: string = '';

  constructor(options: FirebaseProviderOptions) {
    this.options = options;
  }

  init() {}

  getKey() {
    return this.key;
  }

  setKey(_key: string) {
    this.key = _key;
  }

  get REDIRECT_KEY() {
    return `firebaseAuth_redirect_${this.getKey()}`;
  }

  get REDIRECT_URL_KEY() {
    return `firebaseAuth_redirect_url_${this.getKey()}`;
  }

  _getInstance() {
    if (!this.authInstance) {
      this.authInstance = getAuth(this.options.getApp());
    }

    return this.authInstance;
  }

  async _internalGetUser(): Promise<User | null> {
    return new Promise((resolve) => {
      let unsubscribe: () => void;

      const timeout = setTimeout(() => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }

        this.options.logger?.warn('Firebase: get user timeout 10s');
        resolve(null);
      }, 10000);

      unsubscribe = onAuthStateChanged(this._getInstance(), (user: User | null) => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
        clearTimeout(timeout);

        if (!user) {
          this.options.logger?.log('Firebase: user not found');
          return resolve(null);
        }

        resolve(user);
      });
    });
  }

  async signUp({ email, password }: { email: string; password: string }) {
    // firebase is signed in immediately after sign up
    const credential = await createUserWithEmailAndPassword(this._getInstance(), email, password);

    await this.options.hooks.delegate(this.getKey(), {
      accessToken: await credential.user.getIdToken(),
    });

    return credential;
  }

  async getAccessToken(): Promise<string | null> {
    const user = await this._internalGetUser();

    if (!user) {
      return null;
    }

    return await user.getIdToken();
  }

  async isSignedIn(force?: boolean): Promise<boolean> {
    const user = await this._internalGetUser();

    const token = await user?.getIdToken(force);

    if (token) {
      await this.options.hooks.delegate(this.getKey(), {
        accessToken: token,
      });
      return true;
    }

    return false;
  }

  async signIn({ email, password }: { email: string; password: string }): Promise<UserCredential> {
    await this.options.hooks.beforeSignIn();
    try {
      const resp = await signInWithEmailAndPassword(this._getInstance(), email, password);
      await this.options.hooks.delegate(this.getKey(), {
        accessToken: await resp.user.getIdToken(),
      });

      return resp;
    } catch (e) {
      throw e;
    } finally {
      await this.options.hooks.afterSignIn();
    }
  }

  async signInWithRedirect({ redirectUrl, provider }: { provider: 'google' | never; redirectUrl?: string }): Promise<void> {
    await this.options.hooks.beforeSignIn();
    if (redirectUrl) {
      window.sessionStorage.setItem(this.REDIRECT_URL_KEY, redirectUrl);
    }
    window.sessionStorage.setItem(this.REDIRECT_KEY, 'true'); // something other than null is just fine

    try {
      let authProvider;
      // eslint-disable-next-line ts/no-unnecessary-condition
      if (provider === 'google') {
        authProvider = new GoogleAuthProvider();
      }

      await signInWithRedirect(this._getInstance(), authProvider as any as GoogleAuthProvider);
    } catch (e) {
      throw e;
    } finally {
      await this.options.hooks.afterSignIn();
    }
  }

  async signOut() {
    return this._getInstance().signOut();
  }

  async getUser(): Promise<User | null> {
    return await this._internalGetUser();
  }

  async refreshSession() {
    await this.isSignedIn(true);

    const user = await this.getUser();

    if (user) {
      await this.options.hooks.delegate(this.getKey(), {
        accessToken: await user.getIdToken(),
      });
    } else {
      throw new Error('Can not refresh session');
    }
  }

  hasRedirectBack(): boolean {
    return Boolean(window.sessionStorage.getItem(this.REDIRECT_KEY));
  }

  async changePassword({ email, oldPassword, newPassword }: { email: string; oldPassword: string; newPassword: string }) {
    await this.signIn({
      email,
      password: oldPassword,
    });
    const user = await this.getUser();
    if (user) {
      await updatePassword(user, newPassword);
    }
  }

  async sendMailForgotPassword(email: string) {
    await sendPasswordResetEmail(this._getInstance(), email);
  }

  async handleConfirmPasswordReset({ oobCode, newPassword }: { oobCode: string; newPassword: string }) {
    await confirmPasswordReset(this._getInstance(), oobCode, newPassword);
  }

  async handleVerifyPhoneNumber(phoneNumber: string) {
    const applicationVerifier = new RecaptchaVerifier(this._getInstance(), 'recaptcha-container', {
      size: 'invisible',
    });
    const phoneAuthProvider = new PhoneAuthProvider(this._getInstance());
    return await phoneAuthProvider.verifyPhoneNumber(phoneNumber, applicationVerifier);
  }

  async handleUpdatePhoneNumber({ code, verificationId }: { code: string; verificationId: string }) {
    const user = await this.getUser();
    if (user) {
      await updatePhoneNumber(user, PhoneAuthProvider.credential(verificationId, code));
    }
  }

  async handleRedirectCallback(): Promise<{
    credential: UserCredential;
    redirectUrl?: string;
  } | null> {
    try {
      const credential = await getRedirectResult(this._getInstance());

      if (credential) {
        await this.options.hooks.delegate(this.getKey(), {
          accessToken: await credential.user.getIdToken(),
        });

        const redirectUrl = sessionStorage.getItem(this.REDIRECT_URL_KEY);
        if (redirectUrl && isSafeRedirect(redirectUrl, this.options.safeOrigins ?? [window.location.origin])) {
          return {
            credential,
            redirectUrl,
          };
        }

        return {
          credential,
        };
      }

      return null;
    } catch (e) {
      throw e;
    } finally {
      window.sessionStorage.removeItem(this.REDIRECT_KEY);
    }
  }
}
