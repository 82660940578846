import type { Plugin } from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  Bugsnag.start({
    apiKey: '471adfa5ca7a5090c3b1fc509dfee069',
    enabledReleaseStages: ['production'],
    releaseStage: import.meta.env.VITE_ENV,
    appVersion: '1.0.0',
    collectUserIp: false,

    plugins: [new BugsnagPluginVue()],
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  nuxtApp.vueApp.use(bugsnagVue as Plugin);
});
