import { defineStore } from 'pinia';
import type { GetPackagesQuery } from '~/api/user.generated.ts';

export interface PackagesState {
  listPackages: GetPackagesQuery['ws_packages'] | null;
  packagePost: GetPackagesQuery['ws_packages'][0] | null;
  packageRenew: GetPackagesQuery['ws_packages'][0] | null;
  packageHot: GetPackagesQuery['ws_packages'][0] | null;
  packageVip: GetPackagesQuery['ws_packages'][0] | null;
  labelHot: GetPackagesQuery['ws_packages'][0] | null;
  labelVerify: GetPackagesQuery['ws_packages'][0] | null;
  labelHost: GetPackagesQuery['ws_packages'][0] | null;
  isPackagesLoading: boolean;
  fetchPackagesError: Error | null;
}

export const usePackagesStore = defineStore('packages', {
  state(): PackagesState {
    return {
      listPackages: [],
      packagePost: null,
      packageRenew: null,
      packageHot: null,
      packageVip: null,
      labelHot: null,
      labelVerify: null,
      labelHost: null,
      isPackagesLoading: false,
      fetchPackagesError: null,
    };
  },
});
