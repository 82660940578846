<script setup lang="ts">
  import Dialog from 'primevue/dialog';
  import ArtFailedIcon from '~/_components/svg-icons/ArtFailedIcon.vue';
  import ArtSuccessIcon from '~/_components/svg-icons/ArtSuccessIcon.vue';
  import CloseIcon from '~/_components/svg-icons/CloseIcon.vue';
  import { useDialogStore } from '~/modules/shared/stores/dialog-store.ts';
  import MQButton from '~/_components/MQButton.vue';
  import { APP_ENV } from '~/common/config.ts';

  const { noti, hideNoti } = useDialogStore();

  const mapTypeIcon = {
    success: ArtSuccessIcon,
    error: ArtFailedIcon,
  } as const;
</script>

<template>
  <Dialog
    modal
    :visible="noti.visible"
    :pt="{
      root: ['w-full max-w-[448px]'],
    }"
    :onclose="hideNoti"
  >
    <template #container>
      <div class="relative flex flex-col gap-3 rounded-3xl bg-white p-6 text-center shadow-[0px_4px_24px_0px_rgba(0,0,0,0.05)]">
        <CloseIcon
          class="absolute right-4 top-4 cursor-pointer"
          @click="
            () => {
              hideNoti();
              noti?.closeCallback && noti.closeCallback();
            }
          "
        />
        <component :is="mapTypeIcon[noti?.type || 'success']" class="mx-auto" />
        <h2 class="text-2xl font-bold">
          {{ noti.title }}
        </h2>
        <p class="text-secondary-text">
          {{ noti.message }}
        </p>
        <p v-if="APP_ENV !== 'production'" class="text-secondary-text">
          {{ noti.debugMessage }}
        </p>
        <div v-if="Number(noti?.buttons?.length) > 0" class="flex gap-3">
          <MQButton
            v-for="(button, index) in noti?.buttons"
            :key="index"
            :size="button?.size || 'lg'"
            :variant="button?.variant"
            :color="button?.color"
            class="w-[48%]"
            :class="button?.class"
            @click="button?.onClick && button?.onClick()"
          >
            {{ button?.label }}
          </MQButton>
        </div>
        <MQButton
          v-else
          size="lg"
          class="w-full font-bold"
          @click="
            () => {
              hideNoti();
              noti?.closeCallback && noti.closeCallback();
            }
          "
        >
          Close
        </MQButton>
      </div>
    </template>
  </Dialog>
</template>

<style scoped></style>
