<script setup lang="ts"></script>

<template>
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45 31C45 37.0751 40.0751 42 34 42C27.9249 42 23 37.0751 23 31C23 24.9249 27.9249 20 34 20C40.0751 20 45 24.9249 45 31ZM160 100C160 133.137 133.137 160 100 160C66.8629 160 40 133.137 40 100C40 66.8629 66.8629 40 100 40C133.137 40 160 66.8629 160 100ZM176 68C178.761 68 181 65.7614 181 63C181 60.2386 178.761 58 176 58C173.239 58 171 60.2386 171 63C171 65.7614 173.239 68 176 68ZM18 150C18 152.209 16.2091 154 14 154C11.7909 154 10 152.209 10 150C10 147.791 11.7909 146 14 146C16.2091 146 18 147.791 18 150ZM108 36C109.657 36 111 34.6569 111 33C111 31.3431 109.657 30 108 30C106.343 30 105 31.3431 105 33C105 34.6569 106.343 36 108 36ZM166 135C166 136.657 164.657 138 163 138C161.343 138 160 136.657 160 135C160 133.343 161.343 132 163 132C164.657 132 166 133.343 166 135ZM130 180C131.657 180 133 178.657 133 177C133 175.343 131.657 174 130 174C128.343 174 127 175.343 127 177C127 178.657 128.343 180 130 180Z"
      fill="#6DC87D"
    />
    <path
      d="M123.359 90.804L97.6546 116.509C96.6254 117.485 95.3402 118 94.0047 118C92.6693 118 91.384 117.485 90.3548 116.509L77.5024 103.656C75.4992 101.651 75.4992 98.3624 77.5024 96.3567C79.5056 94.351 82.799 94.351 84.8022 96.3567L94.0047 105.609L116.06 83.5043C118.063 81.4986 121.356 81.4986 123.359 83.5043C125.363 85.5099 125.363 88.7984 123.359 90.804Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
