import { defineStore } from 'pinia';
import type { GetMeQuery } from '~/api/user.generated.ts';

export type UserProfile = GetMeQuery['me'][0] & {
  email: string | null;
};

export interface UserState {
  user: UserProfile | null;
  userPermission: Array<{ id: string; district_id: string; permission?: string | null; user_id: string } | undefined>;
  isEagerring: boolean;
  isAuthenticated: boolean;
  isFetchingProfile: boolean;
  authError: Error | null;
  fetchProfileError: Error | null;
  isSigningIn: boolean;
  isSigningOut: boolean;
}

export const useUserStore = defineStore('user', {
  state(): UserState {
    return {
      user: null,
      userPermission: [],
      isEagerring: true,
      isAuthenticated: false,
      authError: null,
      fetchProfileError: null,
      isFetchingProfile: false,
      isSigningIn: false,
      isSigningOut: false,
    };
  },
});
