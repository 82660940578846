import { useUserStore } from '~/modules/shared/stores/user-store.ts';

interface ILogger {
  log: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
}

function copyError(err: Error, newMessage?: string) {
  const message: string = err.message;

  const newErr = new Error(newMessage ?? message);
  newErr.name = err.name;
  Object.assign(newErr, err);
  newErr.message = newMessage ?? message;

  return newErr;
}

function isNil(value: any): value is null | undefined {
  return value == null;
}

function _combineMessages(messages: any[]) {
  const allMessages = messages.reduce<string[]>((combined, current) => {
    switch (true) {
      case typeof current === 'string': {
        // eslint-disable-next-line ts/no-unsafe-argument
        combined.push(current);
        break;
      }
      case current instanceof Error: {
        if (current.name === 'AxiosError') {
          // eslint-disable-next-line ts/no-unsafe-argument
          combined.push(current.message, safeStringify(current.response?.data));
        } else {
          combined.push(current.toString());
        }

        break;
      }
      case isNil(current): {
        combined.push(String(current));

        break;
      }
      case typeof current === 'object': {
        combined.push(safeStringify(current));
        break;
      }
    }
    return combined;
  }, []);

  return allMessages.join(' ');
}

export function getErrorFromMsg(messages: Error | string | any[]) {
  if (messages instanceof Error) {
    return messages;
  }

  if (typeof messages === 'string') {
    return messages;
  }

  if (Array.isArray(messages)) {
    const finalMessage = _combineMessages(messages);
    // if array message has an error, then clone the error (to take the stack), pass all the messages
    const firstError: Error | undefined = messages.find((msg) => msg instanceof Error);
    if (firstError) {
      return copyError(firstError, finalMessage);
    }
    return finalMessage;
  }

  return safeStringify(messages); // last fallback solution
}

function stringifyReplacer(_key: string | number | symbol, value: any) {
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  const specialTypes = ['function', 'bigint', 'symbol'];
  // We use different operation for special kinds
  if (specialTypes.includes(typeof value)) {
    return value.toString();
  }

  return value;
}

function safeStringify(target: any, spacing?: number): string {
  return JSON.stringify(target, stringifyReplacer, spacing);
}

function bugsnagSetUser(bugsnag: any) {
  const userStore = useUserStore();
  bugsnag.setUser(`Id: ${userStore.user?.id}`, `Phone: ${userStore.user?.phone_number}`);
}

function bugsnagNoti(args: any[]) {
  import('@bugsnag/js')
    .then(({ default: bugsnag }) => {
      bugsnagSetUser(bugsnag);
      bugsnag.notify(getErrorFromMsg(args));
    })
    .catch(() => {
      // noop
    });
}

function useLogger(): ILogger {
  return {
    log: (...args: any[]) => {
      // eslint-disable-next-line ts/no-unsafe-argument
      console.log(...args);
    },
    warn: (...args: any[]) => {
      // eslint-disable-next-line ts/no-unsafe-argument
      console.warn(...args);
      try {
        if (typeof document === 'undefined') {
          bugsnagNoti(args);
        }
      } catch (e) {
        // noop
      }
    },
    error: (...args: any[]) => {
      // eslint-disable-next-line ts/no-unsafe-argument
      console.error(...args);
      try {
        if (typeof document === 'undefined') {
          bugsnagNoti(args);
        }
      } catch (e) {
        // noop
      }
    },
  };
}

export default useLogger;
